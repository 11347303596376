<template>
  <section
    v-if="tab === 8"
    class="user-information p-3 bg-white   box-shaddow20"
  >
    <h4 class="text-center ">لیست پیغام ها</h4>
    <LineGrow open-width="90%" />
    <template v-if="status">
      <b-skeleton></b-skeleton>
    </template>
    <template v-else>
      <ul class="panel-notification-container" v-if="notifications">
        <router-link
          class="panel-notification-items"
          v-for="(notification, index) in allNotifications"
          :key="index"
          tag="li"
          :to="notification.to"
        >
          <div class="panel-notification-title">
            <span
              >{{ notification.title }}
              <span
                class="badge badge-danger mr-1"
                style="transform: translateY(-1px)"
                v-if="!notification.read"
                >جدید</span
              >
            </span>
            <span class="created-at">{{
              moment(notification.created_at).format("HH:mm YYYY/MM/DD")
            }}</span>
          </div>
          <div style="opacity: 0;">
            <span>{{ notification.title }}</span>
            <span
              >تاریخ:
              {{
                moment(notification.created_at).format("HH:mm YYYY/MM/DD ")
              }}</span
            >
          </div>
          <div class="panel-notification-description">
            {{ notification.description }}
          </div>
        </router-link>
      </ul>
      <p class="text-center" v-else>هنوز هیچ اعلانی ثبت نشده</p>
    </template>
  </section>
</template>

<script>
import LineGrow from "@/parts/Front/components/shared/LineGrow";
import { BSkeleton } from "bootstrap-vue";

export default {
  components: { LineGrow, BSkeleton },
  props: { tab: { Number } },
  computed: {
    status() {
      return this.$store.getters["front/getFavoriteStatus"];
    },
    notifications() {
      return this.$store.getters["front/getNotifications"];
    },
    allNotifications() {
      return this.notifications.items.map((item) => {
        return item.getNotification();
      });
    },
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
  },
  mounted() {
    if(this.isLogin){

      this.$store.dispatch("front/getNotficationsFromServer");
    }
  },
};
</script>

<style scoped>
.panel-notification-container {
  max-height: 500px;
  overflow-y: scroll;
  border-bottom: 1px solid #1213;
  padding: 8px;
}
.panel-notification-container::-webkit-scrollbar {
  display: none;
}
.panel-notification-items {
  background: white;
  padding: 25px 35px;
  cursor: pointer;
  width: 100%;
  max-width: 100%;
  box-shadow: 0 0 10px -5px #6f6f6f;
  overflow: auto;
  margin: 1.5rem 0;
}
.panel-notification-title {
  display: flex;
  justify-content: space-between;
  font-size: 23px;
}
.panel-notification-title .created-at {
  font-size: 16px;
  color: #5c5c5c;
  word-spacing: 6px;
}
.panel-notification-description {
  margin-top: 20px;
  margin-bottom: 5px;
  text-align: center;
}
</style>
