<template>
    <section
      class="w-100 slider-about-down"
      style="position: relative;"
    >
     
    
    <div id="" class="swiper-container w-100 overflow-hidden position-relative border-r-20 ">
        <div class="swiper-wrapper border-r-20">
          
        
          <div
            class="swiper-slide border-r-20"
            v-for="( index) in 8"
            :key="index"
          >
          <img
          class=" border-r-15 margin-t-0 border-r-20"
          style="width: 100%; height: 100%;"
          src="@/assets/images/Rectangle.png"
          alt=""
        />
          <!-- <img class="w-100 image-top-slid-about" style="height: 100px;" src="@/assets/images/image1.png" alt=""> -->

          </div>
         
        
        </div>
       
      </div>

      <div class="swiper-button-prev box-shaddow20">
       
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="arrow-slie" d="M3.56577 8.98206H14.3838" stroke="#200E32" stroke-width="1.4424" stroke-linecap="round" stroke-linejoin="round"/>
                <path class="arrow-slie" d="M7.92871 4.63733L3.56544 8.98184L7.92871 13.3271" stroke="#200E32" stroke-width="1.4424" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            
      </div>
  <div class="swiper-button-next box-shaddow20">
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="arrow-slie" d="M14.4342 8.98206H3.61621" stroke="#200E32" stroke-width="1.4424" stroke-linecap="round" stroke-linejoin="round"/>
        <path class="arrow-slie" d="M10.0713 4.63733L14.4346 8.98184L10.0713 13.3271" stroke="#200E32" stroke-width="1.4424" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
  </div>
    
    </section>
  </template>
  
  <script>
import Swiper from "swiper/swiper-bundle.min";

export default {
  components: {
   
  },
  mounted() {
    this.initRelatedProducts();
  },
//   computed: {
//     amazingSuggest() {
//       if (this.$store.getters["front/getHomeData"] == null) {
//         return [];
//       } else {
//         return this.$store.getters["front/getHomeData"].mostSales;
//       }
//     },
//   },
//   watch: {
//     amazingSuggest(newValue) {
//       if (newValue) {
//         this.initRelatedProducts(true);
//       }
//     },
//   },
  methods: {
    initRelatedProducts(kill = false) {
      this.initSwiper("asliderAbout", kill, () => {
      let swiper=  new Swiper(".slider-about-down .swiper-container", {
          spaceBetween: 10,
          // centeredSlides: true,
          loop: true,
        slidesPerView: 1,

          speed: 500,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          pagination: {
    el: '.swiper-pagination',
  },
       
        navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
   
        });
        
      });
    
    },
  },
};

  </script>
  
  <style>
 
 .slider-about-down img{
    object-fit: cover;
 }
  .slider-about-down .swiper-button-disabled{
    background-color: #fff !important;
    box-shadow: 0px 0px 5px gray;

  }
  .title-top-slide-about{
    position: relative;
    right: -35px;
  }
  .slider-about-down .swiper-button-disabled .arrow-slie{
    stroke: #000 !important;

  }
  .slider-about-down  .arrow-slie{
    stroke: #000 !important;

  }
  .slider-about-down .swiper-slide-active .image-top-slid-about{
    stroke: #000 !important;
    filter: grayscale(0) !important;


  }
  .slider-about-down  .swiper-pagination-bullet-active{
    width: 20px;
    border-radius: 80px;
  }
  
   .slider-about-down .swiper-button-prev:after,
   .slider-about-down .swiper-button-next:after {
    display: none;
  }
   .slider-about-down .swiper-button-prev,
  .slider-about-down .swiper-button-next {
    background: #fff;
    box-shadow: 0px 0px 10px var(--color-666);

    height: 38px;
    width: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
 .slider-about-down .swiper-button-prev {
    left: -15px;
    top: 50%;
    transform: translateY(50%) !important;


}
.slider-about-down .swiper-button-next {
    right: -15px;
    
    top: 50%;
    transform: translateY(50%) !important;
}
  /*.swiper-button-next {
    left: -10px;
    right: unset;
    transform: translateY(-40px);
  }*/
  @media screen and (max-width:568px) {
    
   .slider-about-down .swiper-button-prev {
      left: -14px;
      width: 30px;
      height: 30px;
    
    
  }
  .slider-about-down .swiper-button-next {
      right: -14px;
      width: 30px;
      height: 30px;

  }
    
  }
  @media screen and (max-width:768px) {
    .slider-about-top  .swiper-pagination{
    display: none !important;
    }
    
  }
  </style>
  