<template>
  <div class="payment-method-grid text-center">
    <a v-for="gateway in gateways" @click="onGatewayClicked(gateway,i)" :key="gateway.id" :class="{activeBank:selectedGateway && gateway.name === selectedGateway.name}" class="box filter-grayscale1 bg-color-ea px-2 pt-2">
      <div class="position-relative payment-method-img">
        <!-- <img class="tick-box" v-if="selectedGateway && gateway.name === selectedGateway.name" :src="require('@/assets/images/tick-box.svg')" alt="tick"> -->
        <img :src="gateway.image" :alt="gateway.name">
      </div>
      <h6 class="fontsize-small text-color-666 my-2">درگاه {{ gateway.label }}</h6>
    </a>
  </div>
</template>

<script>
export default {
  name: "Gateways",

  props: {
    selectedGateway: Object
  },
  computed: {
    gateways() {
      let homeData = this.$store.getters['front/getHomeData'];
      return homeData ? homeData.gateways : [];
    },
  },
  mounted() {

    this.$store.commit('front/addHomeRetrievedEvent', () => {
      if (!this.selectedGateway && this.gateways.length) {
        this.onGatewayClicked(this.gateways[0])
      }
    })
  },
  methods: {
    
    onGatewayClicked(gateway) {
    
      this.$emit('gatewaySelected', gateway)
    }
  }
}
</script>

<style scoped>
.filter-grayscale1{

  filter: grayscale(1);
}
.activeBank{
  filter: grayscale(0)!important;
  border: 1px solid var(--color-theme);
  border-radius: 15px;
  
}
  .tick-box {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
</style>
