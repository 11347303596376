<template>
  <div
      class="modal fade"
      id="withdrawShowModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="withdrawModalLabel"
      aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="withdrawModalLabel">
            جزییات
          </h5>
        </div>
        <div class="modal-body">
          <template v-if="withdraw">
            <div class="mb-3">
              <span class="ml-2">شماره <span class="font-weight-bold">#{{ withdraw.id }}</span></span>
              <span class="badge text-white"
                    :class="{'badge-primary': withdraw.status == 'pending',
                      'badge-success': withdraw.status == 'paid',
                      'badge-danger': withdraw.status == 'canceled',}">
                  {{ getTranslation(withdraw.status) }}
                </span>
            </div>
            <table class="table table-border">
              <thead>
              <tr>
                <th>مبلغ(تومان)</th>
                <th>شماره کارت</th>
                <th>کد رهگیری</th>
                <th>تاریخ</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ withdraw.amount | price }}</td>
                <td>{{ withdraw.card_number }}</td>
                <td>{{ withdraw.tracking_code || '–' }}</td>
                <td>{{ withdraw.created_at | persianDate }}</td>
              </tr>
              </tbody>
            </table>
            <div v-if="withdraw.status === 'pending'">
              <p>برای لغو کردن روی دکمه زیر کلیک کنید. مبلغ به کیف پول شما برخواهد گشت.</p>
              <button :disabled="disabled" @click="cancel" class="btn btn-danger">لغو کردن</button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WithdrawWalletShowModal",
  props: {
    withdraw: Object
  },
  data() {
    return {
      disabled: false,
    }
  },
  methods: {
    getTranslation(key) {
      switch (key) {
        case 'paid':
          return 'پرداخت شده'
        case 'pending':
          return 'در انتظار تکمیل'
        case 'canceled':
          return 'شکست خورده'
      }
    },
    cancel() {
      this.disabled = true
      this.$axios.post(`/customer/withdraws/${this.withdraw.id}/cancel`, {
        amount: this.amount
      }).then(response=>{
        let responseData = response.data.data;
        this.$root.notify(response.data.message, 'success')
        this.user.wallet.balance = this.user.wallet.balance + responseData.withdraw.amount
        this.$store.commit("front/setWithdraw", responseData)
        window.$('#withdrawShowModal').modal('hide')
        this.amount = null
      }).catch(e => {
        this.$root.notify(e)
      }).finally(() => this.disabled=false)
    }
  }
}
</script>

<style scoped>
#app .modal-content {
  background: white;
}
</style>
