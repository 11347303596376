<template>
  <div v-if="$route.name !== 'product.detail'">
    <section
      id="footer-buttons"
      class="footer-buttons d-md-none bg-white px-3 pt-2 d-flex justify-content-around box-shaddow20"
    >
      <router-link
        to="/"
        :class="{
          'home-button': $route.path == '/',
        }"
      >
        <button>
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-width="2.5"
              stroke="#444"
              d="M9.13478 20.7733V17.7156C9.13478 16.9351 9.77217 16.3023 10.5584 16.3023H13.4326C13.8102 16.3023 14.1723 16.4512 14.4393 16.7163C14.7063 16.9813 14.8563 17.3408 14.8563 17.7156V20.7733C14.8539 21.0978 14.9821 21.4099 15.2124 21.6402C15.4427 21.8705 15.7561 22 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428 20.4872 21.0008C21.1356 20.3588 21.5 19.487 21.5 18.5778V9.86686C21.5 9.13246 21.1721 8.43584 20.6046 7.96467L13.934 2.67587C12.7737 1.74856 11.1111 1.7785 9.98539 2.74698L3.46701 7.96467C2.87274 8.42195 2.51755 9.12064 2.5 9.86686V18.5689C2.5 20.4639 4.04738 22 5.95617 22H7.87229C8.55123 22 9.103 21.4562 9.10792 20.7822L9.13478 20.7733Z"
            />
          </svg>
          <p
            class="fontsize13 pb-0  text-color-themeBlue"
            :class="{
              'weight-bold': $route.name != 'cart' && $route.name != 'panel',
            }"
          >
            صفحه اصلی
          </p>
        </button>
      </router-link>
      <button
        @click.prevent="showCategory = !showCategory"
        class="show-category-button"
      >
        <svg
          width="20px"
          height="20px"
          viewBox="0 0 200 200"
          data-name="Layer 1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title />
          <path
            fill="#444"
            d="M75,20H40A20.06,20.06,0,0,0,20,40V75A20.06,20.06,0,0,0,40,95H75A20.06,20.06,0,0,0,95,75V40A20.06,20.06,0,0,0,75,20Zm0,55H40V40H75Zm95-27.5a10,10,0,0,0-10,10V75H125V40h17.5a10,10,0,0,0,0-20H125a20.06,20.06,0,0,0-20,20V75a20.06,20.06,0,0,0,20,20h35a20.06,20.06,0,0,0,20-20V57.5A10,10,0,0,0,170,47.5ZM75,105H40a20.06,20.06,0,0,0-20,20v35a20.06,20.06,0,0,0,20,20H75a20.06,20.06,0,0,0,20-20V125A20.06,20.06,0,0,0,75,105Zm0,55H40V125H75Zm85-55H125a20.06,20.06,0,0,0-20,20v35a20.06,20.06,0,0,0,20,20h35a20.06,20.06,0,0,0,20-20V125A20.06,20.06,0,0,0,160,105Zm0,55H125V125h35Z"
          />
        </svg>
        <p class="fontsize13 pb-0 text-color-444">دسته بندی ها</p>
      </button>
      <a
        :class="{
          'home-button': $route.name == 'cart' && $route.name != 'panel',
        }"
      >
        <button @click="cartListsOpen()" class="">
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.48626 21.5H15.8341C18.9004 21.5 21.2528 20.3924 20.5847 15.9348L19.8066 9.89357C19.3947 7.66931 17.976 6.81805 16.7311 6.81805H6.55262C5.28946 6.81805 3.95308 7.73339 3.4771 9.89357L2.69907 15.9348C2.13157 19.889 4.4199 21.5 7.48626 21.5Z"
              stroke="#444"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.34896 6.59839C7.34896 4.21232 9.28325 2.27802 11.6693 2.27802V2.27802C12.8183 2.27315 13.9219 2.72618 14.7361 3.53694C15.5503 4.34769 16.008 5.44938 16.008 6.59839V6.59839"
              stroke="#444"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.70365 11.1018H8.74942"
              stroke="#444"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.5343 11.1018H14.5801"
              stroke="#444"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p
            class="fontsize13 pb-0 text-color-444"
            :class="{
              'weight-bold': $route.name == 'cart' && $route.name != 'panel',
            }"
          >
            سبدخرید
          </p>
        </button>
      </a>

      <button
        v-if="!isLogin"
        class=""
        data-toggle="modal"
        data-target="#loginModal"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="11.5789"
            cy="7.27803"
            r="4.77803"
            stroke="#444"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.00002 18.7014C3.99873 18.3655 4.07385 18.0337 4.2197 17.7311C4.67736 16.8158 5.96798 16.3307 7.03892 16.111C7.81128 15.9462 8.59431 15.836 9.38217 15.7815C10.8408 15.6533 12.3079 15.6533 13.7666 15.7815C14.5544 15.8367 15.3374 15.9468 16.1099 16.111C17.1808 16.3307 18.4714 16.77 18.9291 17.7311C19.2224 18.3479 19.2224 19.064 18.9291 19.6808C18.4714 20.6419 17.1808 21.0812 16.1099 21.2918C15.3384 21.4634 14.5551 21.5766 13.7666 21.6304C12.5794 21.7311 11.3866 21.7494 10.1968 21.6854C9.92221 21.6854 9.65677 21.6854 9.38217 21.6304C8.59663 21.5773 7.81632 21.4641 7.04807 21.2918C5.96798 21.0812 4.68652 20.6419 4.2197 19.6808C4.0746 19.3747 3.99955 19.0401 4.00002 18.7014Z"
            stroke="#444"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p class="fontsize13 pb-0 text-color-444">پروفایل</p>
      </button>
      <a v-else :class="{ 'home-button': $route.path == '/user-panel' }">
        <button class="">
          <router-link to="/user-panel" style="color: black">
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="11.5789"
                cy="7.27803"
                r="4.77803"
                stroke="#444"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.00002 18.7014C3.99873 18.3655 4.07385 18.0337 4.2197 17.7311C4.67736 16.8158 5.96798 16.3307 7.03892 16.111C7.81128 15.9462 8.59431 15.836 9.38217 15.7815C10.8408 15.6533 12.3079 15.6533 13.7666 15.7815C14.5544 15.8367 15.3374 15.9468 16.1099 16.111C17.1808 16.3307 18.4714 16.77 18.9291 17.7311C19.2224 18.3479 19.2224 19.064 18.9291 19.6808C18.4714 20.6419 17.1808 21.0812 16.1099 21.2918C15.3384 21.4634 14.5551 21.5766 13.7666 21.6304C12.5794 21.7311 11.3866 21.7494 10.1968 21.6854C9.92221 21.6854 9.65677 21.6854 9.38217 21.6304C8.59663 21.5773 7.81632 21.4641 7.04807 21.2918C5.96798 21.0812 4.68652 20.6419 4.2197 19.6808C4.0746 19.3747 3.99955 19.0401 4.00002 18.7014Z"
                stroke="#444"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p
              class="fontsize13 pb-0 text-color-444"
              :class="{ 'weight-bold': $route.name == 'panel' }"
            >
              حساب کاربری
            </p>
          </router-link>
        </button>
      </a>
    </section>
    <!-- مدیریت و نمایش همه دسته بندی ها -->
    <AllCategory v-model="showCategory"></AllCategory>
  </div>
</template>

<script>
import { cartListsOpen } from "@/libraries/functions";
import AllCategory from "../components/allCategory/AllCategory.vue";
export default {
  name: "FooterButtons",
  data() {
    return {
      showCategory: false,
    };
  },
  methods: {
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "other";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "Ios";
      }

      return "other";
    },
    checkIosConfig() {
      if (this.getMobileOperatingSystem() == "Ios") {
        setTimeout(() => {
          document.getElementById("footer-buttons").style.paddingBottom =
            "32px";
        }, 50);
      }
    },
    cartListsOpen() {
      cartListsOpen();
      this.$router.push(this.$route.fullPath + "#cart-modal");
    },
  },
  components: {
    AllCategory,
  },
  mounted() {
    this.$nextTick(() => {
      this.checkIosConfig();
    });
  },
  computed: {
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
  },
  watch: {
    $route: {
      handler: function(val) {
        if (val.name !== "product.detail") {
          this.checkIosConfig();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
/* این مقدار زد ایندکس باید از مقدار زد ایندکس کامپوننت ال کته گوری آیدی کلوز آل کته گوری بیشتر باشد */
#footer-buttons {
  z-index: 100;
}
</style>
<style>
.footer-buttons p {
  margin-bottom: 10px !important;
}
</style>
