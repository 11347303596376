import { render, staticRenderFns } from "./info-down-about.vue?vue&type=template&id=2e527e2b&scoped=true"
import script from "./info-down-about.vue?vue&type=script&lang=js"
export * from "./info-down-about.vue?vue&type=script&lang=js"
import style0 from "./info-down-about.vue?vue&type=style&index=0&id=2e527e2b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e527e2b",
  null
  
)

export default component.exports