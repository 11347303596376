<template>
  <section
    v-if="tab === 7 && answer"
    id="answerSection"
    class="user-information p-3 bg-white box-shaddow20"
  >
    <h4 class="text-center ">پاسخ تماس با ما</h4>
    <LineGrow open-width="90%" />
    <b-skeleton-table
      v-if="status"
      :rows="5"
      :columns="4"
      :table-props="{ bordered: false, striped: false }"
    ></b-skeleton-table>
    <template v-else>
      <div v-for="item in answer.data" :key="item.id">
        <details>
          <summary>
            <div class="summary-chevron-up">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-down"
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </div>
            {{ item.body }}
          </summary>
          <p v-if="item.answer" class="summary-content">
            {{ item.answer }}
          </p>
          <p v-else class="summary-content">
            هنوز پاسخی داده نشده است
          </p>
        </details>
      </div>
      <h4 class="text-center w-100 mt-5" v-if="answer.data.length === 0">
        آیتمی جهت نمایش وجود ندارد
      </h4>
    </template>
    <section
      id="mainPaginationBlog"
      v-if="answer && answer.last_page > 1"
      class="weblog-list-pagination text-center my-4 p-2 bg-color-fa"
    >
      <section
        v-if="answer"
        id="listPagination"
        class="list-pagination weblog-list-pagination text-center my-4 py-2"
      >
        <pagination
          :limit="1"
          :data="answer"
          @pagination-change-page="changePage"
          :disabled="status"
          class="justify-content-center"
        >
          <span slot="prev-nav">
            <ZPrevSvg />
          </span>
          <span slot="next-nav">
            <ZNextSvg />
          </span>
        </pagination>
      </section>
    </section>
  </section>
</template>
<script>
import paginationMixin from "@/libraries/mixins/paginationMixin";
import ZPrevSvg from "@/parts/Front/components/shared/ZPrevSvg";
import ZNextSvg from "@/parts/Front/components/shared/ZNextSvg";
import LineGrow from "@/parts/Front/components/shared/LineGrow";
import { BRow, BCol, BSkeletonTable } from "bootstrap-vue";
export default {
  mixins: [paginationMixin],
  props: { tab: { Number } },
  components: {
    LineGrow,
    ZNextSvg,
    ZPrevSvg,
    BRow,
    BCol,
    BSkeletonTable,
  },
  data() {
    return {
      loading: false,
      allow: false,
    };
  },
  computed: {
    status() {
      return this.$store.getters["front/getAnswerStatus"];
    },
    answer() {
      return this.$store.getters["front/getAnswer"];
    },
  },

  // created() {
  //   if(this.answer == null){
  //     this.$store.dispatch(
  //     "front/getAnswerFromServer",
  //     this.$route.query.page != undefined ? this.$route.query.page : "1"
  //   );
  //   }
  // },

  mounted() {
    setTimeout(() => {
      this.allow = true;
    }, 5000);

    if (!this.answer) {
      this.$store.dispatch(
        "front/getAnswerFromServer",
        this.$route.query.page != undefined ? this.$route.query.page : "1"
      );
    }
  },

  watch: {
    answer(newVal) {
      if (newVal != null) {
        this.loading = true;
      }
    },

    "$route.query.page": {
      handler: function(val) {
        let page = val;
        if (this.allow) this.$store.dispatch("front/getAnswerFromServer", page);
        document
          .getElementById("answerSection")
          .scrollIntoView({ behavior: "smooth" });
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
details {
  font-size: 1rem;
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.1),
    0 5px 5px -5px rgba(0, 0, 0, 0.04);
  width: 97%;
  margin: 2rem auto;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #e2e8f0;
  position: relative;
}
details:hover {
  cursor: pointer;
}
details .summary-content {
  border-top: 1px solid #e2e8f0;
  font-family: "Yekan Bakh Regular";
  cursor: default;
  padding: 1em;
  font-weight: 300;
  line-height: 1.5;
  background-color: rgb(238, 236, 236);
}
details summary {
  list-style: none;
  padding: 1rem 2rem 1rem 1rem;
  font-family: "Yekan Bakh Regular";
  font-weight: 300;
  line-height: 1.5;
}
details .summary-chevron-up {
  pointer-events: none;
  position: absolute;
  right: 4px;
  top: 15px;
}
details summary:hover .summary-chevron-up svg {
  opacity: 1;
}
details .summary-chevron-up svg {
  opacity: 0.5;
}
details .summary-chevron-up svg {
  display: block;
}
details > summary::-webkit-details-marker {
  display: none;
}
</style>
