<template>
  <section class="cart-section   px-2 py-4 mb-5">
    <div class="cart-table">
      <div
        class="cart-table-head cart-table-grid text-center border-bottom py-2 px-3"
      >
        <h6 class="text-right  weight-bold text-color-444 ">
          سبد خرید شما
        </h6>
        <h6 class=" text-color-444">قیمت واحد</h6>
        <h6 class=" text-color-444">وزن واحد</h6>
        <h6 class=" text-color-444">تعداد</h6>
        <h6 class=" text-color-444 ">حذف</h6>
      </div>
      <template
        v-if="cart != null && cart.cartItems != null && cart.cartItems != ''"
      >
        <CartItem
          :defualtWeight="defualtWeight"
          @quantityChanged="recalculateCoupon"
          @changeCoupen="recalculateCoupon"
          v-for="(cartItem, index) in cart.cartItems"
          :key="index"
          :class="{'border-bottom': index != cart.cartItems.length-1}"
          v-model="cart.cartItems[index]"
          :index="index"
          :warning="
            warning != ''
              ? warning.filter((item) => item.cart_id == cartItem.id)[0]
              : null
          "
          :deletedCartIds="
            deletedCartIds != ''
              ? deletedCartIds.filter((item) => item == cartItem.id)[0]
              : null
          "
        />
      </template>
      <template v-else>
        <div class="text-center mt-5">
          <svg
            width="50"
            height="50"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.9303 7C16.9621 6.92913 16.977 6.85189 16.9739 6.77432H17C16.8882 4.10591 14.6849 2 12.0049 2C9.325 2 7.12172 4.10591 7.00989 6.77432C6.9967 6.84898 6.9967 6.92535 7.00989 7H6.93171C5.65022 7 4.28034 7.84597 3.88264 10.1201L3.1049 16.3147C2.46858 20.8629 4.81062 22 7.86853 22H16.1585C19.2075 22 21.4789 20.3535 20.9133 16.3147L20.1444 10.1201C19.676 7.90964 18.3503 7 17.0865 7H16.9303ZM15.4932 7C15.4654 6.92794 15.4506 6.85153 15.4497 6.77432C15.4497 4.85682 13.8899 3.30238 11.9657 3.30238C10.0416 3.30238 8.48184 4.85682 8.48184 6.77432C8.49502 6.84898 8.49502 6.92535 8.48184 7H15.4932ZM9.097 12.1486C8.60889 12.1486 8.21321 11.7413 8.21321 11.2389C8.21321 10.7366 8.60889 10.3293 9.097 10.3293C9.5851 10.3293 9.98079 10.7366 9.98079 11.2389C9.98079 11.7413 9.5851 12.1486 9.097 12.1486ZM14.002 11.2389C14.002 11.7413 14.3977 12.1486 14.8858 12.1486C15.3739 12.1486 15.7696 11.7413 15.7696 11.2389C15.7696 10.7366 15.3739 10.3293 14.8858 10.3293C14.3977 10.3293 14.002 10.7366 14.002 11.2389Z"
              fill="#999"
            />
          </svg>
          <h5 class="text-color-999 pt-3 fontsize-large">
            سبد خرید شما خالی می باشد.
          </h5>
        </div>
      </template>
    </div>

    <section
      class="total-price d-md-flex border-radius20 flex-row-reverse justify-content-between m-3 p-3 bg-color-eai"
    >
    
      <div class="col-md-5 col-12">
        <div class="text-color-444 d-flex justify-content-between mb-2">
          <span class="fontsize-medium weight-bold"> مجموع وزن ها : </span>
          <span>
            <span class="fontsize-medium ml-1">{{ sumWeight | price }}</span>
            <span class="fontsize-small text-color-999"> گرم </span>
          </span>
        </div>
        <div class="text-color-444 d-flex justify-content-between mb-2">
          <span class="fontsize-medium weight-bold"
            >مجموع قیمت های کالا :
          </span>
          <span>
            <span class="fontsize-medium ml-1" v-if="totalPrice != 0">{{
              (totalPrice + totalSaving) | price
            }}</span>
            <span class="fontsize-medium ml-1" v-else>0</span>
            <span class="fontsize-small text-color-999">تومان</span>
          </span>
        </div>
        <div class="text-color-444 d-flex justify-content-between mb-2">
          <span class="fontsize-medium weight-bold"> تخفیف : </span>
          <span>
            <span class="fontsize-medium ml-1">{{ totalSaving | price }}</span>
            <span class="fontsize-small text-color-999">تومان</span>
          </span>
        </div>

        <div
          v-if="discount && discount.discount != 0"
          class="text-color-444 d-flex justify-content-between mb-2"
        >
          <span class="fontsize-medium weight-bold"> تخفیف از کوپن : </span>
          <span>
            <span class="fontsize-medium ml-1">{{
              discount.discount | price
            }}</span>
            <span class="fontsize-small text-color-999">تومان</span>
          </span>
        </div>
        <div class="text-color-themeGreen d-flex justify-content-between mb-2">
          <span class="fontsize-medium weight-bold"> پرداخت نهایی : </span>
          <span class="text-color-themeGreen">
            <span class="fontsize-large  ml-1 weight-bold " v-if="total != 0">{{
              total | price
            }}</span>
            <span class="fontsize-medium ml-1" v-else>0</span>
            <span class="fontsize-small ">تومان</span>
          </span>
        </div>
      </div>
      <form
      @submit.prevent="submitCoupon()"
      class="total-price-discount-btn d-flex align-items-center text-color-444
        align-self-center bg-none px-3 py-2 mr-md-4 fontsize-small weight-bold"
    >
      <!--          <svg class="ml-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
      <!--            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3991 9.14599L21.1193 9.86599C21.6895 10.426 21.9996 11.186 21.9996 11.986C22.0096 12.786 21.6995 13.547 21.1393 14.116C21.1327 14.1233 21.126 14.1298 21.1193 14.1362C21.116 14.1394 21.1127 14.1427 21.1093 14.146L20.3991 14.856C20.119 15.136 19.9589 15.516 19.9589 15.917V16.946C19.9589 18.606 18.6084 19.957 16.9478 19.957H15.9174C15.5173 19.957 15.1372 20.116 14.8571 20.396L14.1368 21.116C13.5466 21.707 12.7763 21.996 12.006 21.996C11.2357 21.996 10.4655 21.707 9.87525 21.127L9.14498 20.396C8.86488 20.116 8.48474 19.957 8.0846 19.957H7.05423C5.39362 19.957 4.04313 18.606 4.04313 16.946V15.917C4.04313 15.516 3.88308 15.136 3.60298 14.846L2.88271 14.136C1.71229 12.967 1.70229 11.056 2.87271 9.87699L3.60298 9.14599C3.88308 8.86599 4.04313 8.48599 4.04313 8.07599V7.05599C4.04313 5.39599 5.39362 4.04699 7.05423 4.04699H8.0846C8.48474 4.04699 8.86488 3.88599 9.14498 3.60599L9.86524 2.88599C11.0357 1.70699 12.9464 1.70699 14.1268 2.87699L14.8571 3.60599C15.1372 3.88599 15.5173 4.04699 15.9174 4.04699H16.9478C18.6084 4.04699 19.9589 5.39599 19.9589 7.05599V8.08699C19.9589 8.48599 20.119 8.86599 20.3991 9.14599ZM9.42509 15.446C9.66517 15.446 9.88525 15.356 10.0453 15.186L15.1872 10.047C15.5273 9.70699 15.5273 9.14599 15.1872 8.80599C14.8471 8.46699 14.2969 8.46699 13.9567 8.80599L8.81486 13.946C8.47474 14.286 8.47474 14.846 8.81486 15.186C8.97492 15.356 9.195 15.446 9.42509 15.446ZM13.6966 14.566C13.6966 15.056 14.0868 15.446 14.577 15.446C15.0571 15.446 15.4473 15.056 15.4473 14.566C15.4473 14.087 15.0571 13.696 14.577 13.696C14.0868 13.696 13.6966 14.087 13.6966 14.566ZM9.43509 8.55599C9.91526 8.55599 10.3054 8.94599 10.3054 9.42599C10.3054 9.91699 9.91526 10.306 9.43509 10.306C8.95492 10.306 8.55477 9.91699 8.55477 9.42599C8.55477 8.94599 8.95492 8.55599 9.43509 8.55599Z" fill="#f00"/>-->
      <!--          </svg>-->
      <input
        type="text"
        class="discount-input"
        placeholder="کد تخفیف را وارد کنید"
        :disabled="!!discount"
        v-model="couponCode"
      />
      <div v-if="!discount" class="d-flex discount-btn text-color-white mr-1">
        <span>
          <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.9835 10.7865L18.581 9.38399C18.3411 9.14409 18.1473 8.67351 18.1473 8.34134V6.3483C18.1473 5.53633 17.483 4.87198 16.671 4.87198H14.6872C14.355 4.87198 13.8845 4.67822 13.6446 4.43831L12.2421 3.03581C11.67 2.46373 10.7288 2.46373 10.1567 3.03581L8.73579 4.43831C8.50511 4.67822 8.03454 4.87198 7.69314 4.87198H5.70933C4.89735 4.87198 4.23301 5.53633 4.23301 6.3483V8.33211C4.23301 8.66429 4.03924 9.13486 3.79934 9.37477L2.39683 10.7773C1.82475 11.3493 1.82475 12.2905 2.39683 12.8626L3.79934 14.2651C4.03924 14.505 4.23301 14.9756 4.23301 15.3077V17.2915C4.23301 18.1035 4.89735 18.7679 5.70933 18.7679H7.69314C8.02531 18.7679 8.49589 18.9616 8.73579 19.2015L10.1383 20.604C10.7104 21.1761 11.6515 21.1761 12.2236 20.604L13.6261 19.2015C13.866 18.9616 14.3366 18.7679 14.6688 18.7679H16.6526C17.4645 18.7679 18.1289 18.1035 18.1289 17.2915V15.3077C18.1289 14.9756 18.3227 14.505 18.5626 14.2651L19.9651 12.8626C20.5648 12.2997 20.5648 11.3586 19.9835 10.7865ZM7.49937 9.06105C7.49937 8.55356 7.91458 8.13835 8.42207 8.13835C8.92956 8.13835 9.34477 8.55356 9.34477 9.06105C9.34477 9.56853 8.93878 9.98375 8.42207 9.98375C7.91458 9.98375 7.49937 9.56853 7.49937 9.06105ZM8.9111 15.0863C8.7727 15.2247 8.59738 15.2893 8.42207 15.2893C8.24676 15.2893 8.07144 15.2247 7.93304 15.0863C7.66546 14.8187 7.66546 14.3758 7.93304 14.1082L13.4692 8.57202C13.7368 8.30443 14.1797 8.30443 14.4473 8.57202C14.7149 8.8396 14.7149 9.2825 14.4473 9.55008L8.9111 15.0863ZM13.9583 15.52C13.4416 15.52 13.0264 15.1047 13.0264 14.5973C13.0264 14.0898 13.4416 13.6746 13.9491 13.6746C14.4565 13.6746 14.8718 14.0898 14.8718 14.5973C14.8718 15.1047 14.4658 15.52 13.9583 15.52Z" fill="white"/>
            </svg>
            
        </span>
        <input
          
          :disabled="disabled"
          type="submit"
          class="bg-trancparent text-color-white"
          value="ثبت کد تخفیف"
        />
      </div>
      <div v-else class="discount-btn text-color-white mr-3">

        <input
          type="button"
          @click="removeCoupon"
          class="bg-trancparent text-color-white"
          value="حذف کد تخفیف"
        />
      </div>
    </form>
    </section>
    <div
      class="d-flex justify-content-md-between flex-row-reverse justify-content-end align-items-baseline px-3 py-2"
    >
     
      <div
        id="next-btn-step-1-container"
        class="position-relative  w-25 px-sm-0 "
      >
        <button
          id="next-btn-step-1"
          class="text-white continue-buy-process py-2 w-100 fontsize-medium text-center"
          @click="nextPage"
          data-placement="top"
          v-b-tooltip.focus
          :disabled="!isCartChecked || cartIsEmpty || disabled"
        >
          <span v-if="isCartChecked">ادامه فرآیند خرید</span>
          <span v-else-if="isCartChecked === null">در حال بارگزاری</span>
          <span v-else>عملیات به مشکل خورد</span>
        </button>
        <b-tooltip v-if="cartIsEmpty" target="next-btn-step-1-container"
          >{{ cartIsEmpty ? "سبد خرید شما خالی است" : "" }}
        </b-tooltip>
      </div>
      <p class="fontsize-small text-color-666 d-md-block d-none">
        افزودن کالا به سبد خرید به معنی رزرو آن نیست با توجه به محدودیت موجودی
        سبد خود را ثبت و خرید را تکمیل کنید.
      </p>
    </div>
  </section>
</template>
<script>
import CartItem from "@/parts/Front/components/cart/CartItem";
import cart from "@/parts/Front/components/cart/cart";
import { BTooltip, VBTooltip } from "bootstrap-vue";
import axios from "axios";
import * as vueMethods from "../../../../libraries/VueMethods";
import { getProductWeight } from "@/libraries/functions";

export default {
  name: "CartStep1",
  components: {
    CartItem,
    BTooltip,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    homeData: Object,
    isCartChecked: Boolean,
    couponCodeProp: String,
  },
  data() {
    return {
      disabled: false,
      couponCode: this.couponCodeProp,
      warning: [],
      deletedCartIds: [],
      // تا زمانی که وارنینگ ها لود نشد اجازه نده ادامه بده
      // false = یعنی ارور داد
    };
  },
  watch: {
    changeCart(newV) {
      if (newV && this.discount) {
        this.submitCoupon(true);
      }
    },
    cart(newV) {
      if (newV) this.checkExtraShipping();
    },
    // وقتی لاگین کرد دوباره چک بشه سبد خرید
    "$store.state.front.1.homeData.user.login"(isLoggedIn) {
      if (isLoggedIn == true) {
        // قرار دادن در وضعیت در حال برگزاری
        this.$emit("cartChecked", null);
        this.$nextTick(() => {
          this.init();
        });
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    checkExtraShipping() {
      let findItem = this.cart.cartItems.find((cartItem) => {
        if (cartItem.variety.weight&&
          cartItem.variety.weight >
          Number(
            this.$store.getters["front/getHomeData"].settings.shipping_amount
              .overweight_shipping_amount
          )
        ) {
          return cartItem;
        }else if(
          cartItem.variety.product&&
          cartItem.variety.product.weight >
          Number(
            this.$store.getters["front/getHomeData"].settings.shipping_amount
              .overweight_shipping_amount
          )
        ){
          return cartItem;
        }
        // return (
        //   cartItem.variety.weight >
        //   Number(
        //     this.$store.getters["front/getHomeData"].settings.shipping_amount
        //       .overweight_shipping_amount
        //   )
        // );
      });
      console.log(findItem);
      if (findItem) {
        this.$store.commit("front/setHasExtraWeight", true);
      }
    },
    getProductWeight,
    init() {
      if (
        this.$store.getters["front/getLoginStatus"] &&
        this.$store.getters["front/getHomeData"] &&
        !this.isCartChecked
      ) {
        this.$axios
          .get('customer/cart?show_warning=1&widgets=["shippings", "gateways"]')
          .then((response) => {
            this.$emit("cartChecked", true);
            const res = response.data.data;
            this.$emit("shippingsLoaded", res.widgets.shippings);
            this.$emit("gatewaysLoaded", res.widgets.gateways);
            this.$store.commit("front/setUserCart", {
              priority: 2,
              cart: new cart(this.$root, res.carts, "server"),
            });
            this.warning = res.messages.warning;
            this.deletedCartIds = res.messages.deleted_cart_ids;
            this.$emit("setReservations", res.reservations);
          })
          .catch((error) => {
            this.$emit("cartChecked", false);
          });
      } else if (
        !this.$store.getters["front/getLoginStatus"] &&
        this.$store.getters["front/getHomeData"]
      ) {
        this.reloadCart();
      } else {
        this.$emit("cartChecked", true);
      }
    },
    nextPage() {
      // اگر لاگین نبود مدال نشون بده
      if (!this.isLogin) {
        document.querySelector('button[data-target="#loginModal"]').click();
        return;
      }
      let fixedCarts = this.getFixedCartItems();
      this.$store.commit("front/setUserCart", {
        priority: 3,
        cart: new cart(this.$root, fixedCarts, "server"),
      });
      this.$emit("next-page");
    },
    getFixedCartItems() {
      let carts = this.cart.cartItems;
      return carts.filter((item) => {
        if (this.deletedCartIds.includes(item.id)) {
          return;
        } else {
          return item;
        }
      });
    },
    async submitCoupon(reCalculating = false) {
      if (!this.isLogin) {
        document.querySelector('button[data-target="#loginModal"]').click();
        return;
      }
      this.disabled = true;
      try {
        const response = await this.$axios.post("customer/coupon/verify", {
          code: this.couponCode,
          total_price: this.totalPrice,
        });
        this.$store.commit("front/setChangeCartInfo", false);

        this.$emit("couponApplied", this.couponCode);
        !reCalculating &&
          this.$root.notify("کد تخفیف با موفقیت اعمال شد", "success");
        this.$store.commit(
          "front/setDiscountCoupen",
          response.data.data.discount
        );
        // this.$emit("discountApplied", );
      } catch (error) {
        if (reCalculating) {
          this.removeCoupon();
        } else {
          this.$root.notify(error);
        }
      }

      this.disabled = false;
    },
    async reloadCart() {
      axios.defaults.headers.common["Authorization"] = vueMethods.getCookie(
        "Authorization"
      );
      const response = await axios.get("/all/cart/get", {
        params: { ...cart.getCartRequestQueryString() },
      });
      const data = response.data.data;
      if (data.user.user) {
        this.$store.commit("front/setLoginStatus", data.user);
      } else {
        let initialCarts = cart.getInitialCartForCookieMode(data.cart_request);
        this.$store.commit("front/setUserCart", {
          priority: 0,
          cart: new cart(this, initialCarts, "cookie"),
        });
        this.$emit("cartChecked", true);
      }
      let initialCarts = cart.getInitialCartForCookieMode(data.cart_request);
      this.$store.commit("front/setUserCart", {
        priority: 0,
        cart: new cart(this, initialCarts, "cookie"),
      });
    },
    removeCoupon() {
      this.$store.commit("front/setDiscountCoupen", null);

      // this.$emit("discountApplied", null);
      this.$emit("couponApplied", "");
    },
    recalculateCoupon() {
      if (this.discount) {
        this.submitCoupon(true);
      }
    },
  },
  computed: {
    changeCart() {
      return this.$store.getters["front/getChangeCartInfo"];
    },
    discount() {
      return this.$store.getters["front/getDiscountCoupen"];
    },

    defualtWeight() {
      return this.homeData != null
        ? Number(this.homeData.settings.shipping_amount.defualt_product_weight)
        : -1;
    },
    sumWeight() {
      let sum = 0;
      if (
        this.cart != null &&
        this.cart.cartItems != null &&
        this.cart.cartItems != ""
      ) {
        this.cart.cartItems.forEach((cart) => {
          sum =
            sum +
            Number(this.getProductWeight(cart, this.defualtWeight)) *
              cart.quantity;
        });
      } else {
        sum = 0;
      }
      return sum;
    },
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
    cart() {
      if (
        this.$store.getters["front/getHomeData"] != null &&
        this.$store.getters["front/getHomeData"].user.cart.cartItems != null
      ) {
        return this.$store.getters["front/getHomeData"].user.cart;
      } else {
        return null;
      }
    },
    cartIsEmpty() {
      if (!this.cart) {
        return false;
      }
      let fixedCartItems = this.getFixedCartItems();

      return fixedCartItems.length && fixedCartItems.length === 0;
    },
    total() {
      return this.cart
        ? this.cart.getTotalPrice(this.deletedCartIds) -
            (this.discount ? this.discount.discount : 0)
        : 0;
    },
    totalSaving() {
      return this.cart ? this.cart.getTotalSaving(this.deletedCartIds) : 0;
    },
    totalPrice() {
      return this.cart ? this.cart.getTotalPrice(this.deletedCartIds) : 0;
    },
  },
};
</script>
<style scoped>
.cart-table-head{
  font-size: 14px !important;
}
.discount-btn {
  background: var(--color-theme);
  padding: 6px 8px;
  color: #fff;
  border-radius: 8px;
  gap: 8px;
  align-items: center;
}
.discount-btn span{
transform: translateY(-1px);
display: block;
}

.discount-input {
  padding: 6px 5px;
}
.discount-input::placeholder {
  color: #8b8b8b;
}
.discount-btn:disabled {
  opacity: 0.7;
  background: #dcdcdc;
}
.discount-btn {
  transition: 200ms all;
}
.discount-btn:hover {
  color: #ffffff !important;
  background: var(--color-theme);
}
.continue-buy-process {
  color: white;
  font-size: 14px;
  height: 50px;
  transition: 0.3s;
  border-radius: 15px;
}

/* .continue-buy-process:hover {
  background-color: var(--color-theme4);
} */
@media screen and (max-width:768px) {
  #next-btn-step-1-container{
    width: 100% !important;
  }
}
</style>
