<template>
  <section class="test mt-4 p-4 container">
    <div class="">
      <infoDownAboutSlider />
    </div>
    <div class="newsParageraph">
      <h2 class="text-color-theme font-bold fontsize-16 weight-bold mb-3">
        ما بهترین کیفیت را برای شما به ارمغان می آوریم.
      </h2>
      <p class="line-height2 text-color-666 fontsize14 text-justify">
        پلورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده
        از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و
        سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای
        متنوع با هدف بهبود ابزارهای کاربردی می باشد، می ری موجود در ارائه
        راهکارها، و شرایط سخت تایپ به پایان قرار گیرد.لورم ایپسوم متن ساختگی با
        تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است،
        چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و
        برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود
        ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و
        آینده، شناخت .لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ،
        و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله
        پلورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده
        از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و
        سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای
        متنوع با هدف بهبود ابزارهای کاربردی می باشد، می ری موجود در ارائه
        راهکارها، و شرایط سخت تایپ به پایان قرار گیرد.لورم ایپسوم متن ساختگی با
        تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از ایپسوم متن ساختگی با
        تولید سادگی نامفهوم از صنعت چاپ، و با استفاده فهوم از صنعت چاپ، و با
        استفاده از ایپسوم متن ساختگی با تولید مفهوم پلورم ایپسوم متن ساختگی با
        تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است،
        چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و
        برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود
        ابزارهای کاربردی می باشد، می ری موجود در ارائه راهکارها، و شرایط سخت
        تایپ به پایان قرار گیرد.لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
        صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه
        و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد
        نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای
        زیادی در شصت و سه درصد گذشته حال و آینده، شناخت .لورم ایپسوم متن ساختگی
        با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است،
        چاپگرها و متون بلکه روزنامه و مجله پلورم ایپسوم متن ساختگی با تولید
        سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و
        متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط
        فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی
        می باشد، پلورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و
        با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در
        ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و
        کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، می ری موجود در
        ارائه راهکارها، و شرایط سخت تایپ به پایان قرار گیرد.لورم ایپسوم متن
        ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک
        است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است،
        و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود
        ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و
        آینده، شناخت .لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ،
        و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله
        پلورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده
        از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و
        سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای
        متنوع با هدف بهبود ابزارهای کاربردی می باشد،
      </p>
    </div>
  </section>
</template>
<script>
import infoDownAboutSlider from "@/parts/Front/components/about-us/info-down-about-slider";

export default {
  components: {
    infoDownAboutSlider,
  },
};
</script>
<style scoped>
.test {
}
.test div:nth-child(1) {
  float: right;
  margin: 0 0 10px 26px;
  width: 500px;
}
.test div:nth-child(2) {
  text-align: justify;
  line-height: 2;
}
@media screen and (max-width: 992px) {
  .test div:nth-child(1) {
    float: unset !important;
    width: 100%;
  }
  .newsParageraph {
    margin-top: 2rem;
  }
  .newsParageraph h2 {
    font-size: 24px;
  }
}
</style>
