<template>
  <article
    v-if="address != null"
    class="text-black user-address p-3  w-100   bg-color-theme2 mx-2 mb-3"  :class="selectedAddress && selectedAddress.id == address.id ? 'user-address-active' : '' "
  >
    <div class="d-flex justify-content-between in-address" >
      <div class="d-flex ml-2">
        <div class="align-self-center ml-2">
          <label class="address-checked-container">
            <input type="radio" class="selected-radio" name="userAddress" :checked="selectedAddress && selectedAddress.id == address.id" />
            <span class="address-checkmark"></span>
          </label>
        </div>
        <div class="text-right mr-4">
          <div>
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.5 10.3178C3.5 5.71789 7.34388 2 11.9934 2C16.6561 2 20.5 5.71789 20.5 10.3178C20.5 12.6357 19.657 14.7876 18.2695 16.6116C16.7388 18.6235 14.8522 20.3765 12.7285 21.7524C12.2425 22.0704 11.8039 22.0944 11.2704 21.7524C9.13474 20.3765 7.24809 18.6235 5.7305 16.6116C4.34198 14.7876 3.5 12.6357 3.5 10.3178ZM9.19423 10.5768C9.19423 12.1177 10.4517 13.3297 11.9934 13.3297C13.5362 13.3297 14.8058 12.1177 14.8058 10.5768C14.8058 9.0478 13.5362 7.77683 11.9934 7.77683C10.4517 7.77683 9.19423 9.0478 9.19423 10.5768Z"
                fill="#444"
              />
            </svg>
            <span class="fontsize-small mr-1">
              <span v-if="address.city"
                >{{
                  address.city.province
                    ? address.city.province.name + " - "
                    : ""
                }}
                {{ address.city.name }}</span
              >
              - {{ address.address }}</span
            >
          </div>
          <div class="d-flex address-row2">

          
          <div class="my-2">
            <span class="d-inline-block">
              <!-- <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.294 7.29105C17.294 10.2281 14.9391 12.5831 12 12.5831C9.0619 12.5831 6.70601 10.2281 6.70601 7.29105C6.70601 4.35402 9.0619 2 12 2C14.9391 2 17.294 4.35402 17.294 7.29105ZM12 22C7.66237 22 4 21.295 4 18.575C4 15.8539 7.68538 15.1739 12 15.1739C16.3386 15.1739 20 15.8789 20 18.599C20 21.32 16.3146 22 12 22Z"
                fill="#777"
              />
            </svg> -->

            <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.11321 14.3428C7.68406 14.3428 7.24878 14.1772 6.92385 13.8523L5.87548 12.8162C5.61799 12.5587 5.26242 12.4177 4.90071 12.4177H4.43477C3.16571 12.4177 2.13574 11.3939 2.13574 10.1371V3.44232C2.13574 2.18552 3.16571 1.16168 4.43477 1.16168H11.7917C13.0607 1.16168 14.0907 2.18552 14.0907 3.44232V10.131C14.0907 11.3878 13.0607 12.4116 11.7917 12.4116H11.3257C10.964 12.4116 10.6084 12.5587 10.3509 12.8101L9.30258 13.8462C8.97765 14.1772 8.54236 14.3428 8.11321 14.3428ZM4.43477 2.08129C3.67456 2.08129 3.05535 2.69437 3.05535 3.44232V10.131C3.05535 10.885 3.67456 11.492 4.43477 11.492H4.90071C5.50765 11.492 6.09618 11.7372 6.52533 12.1602L7.5737 13.1963C7.87411 13.4906 8.35845 13.4906 8.65886 13.1963L9.70719 12.1602C10.1363 11.7372 10.7249 11.492 11.3319 11.492H11.7917C12.5519 11.492 13.1711 10.8789 13.1711 10.131V3.44232C13.1711 2.68824 12.5519 2.08129 11.7917 2.08129H4.43477Z" fill="#202020"/>
              <path d="M8.15623 6.34218C8.14397 6.34218 8.12558 6.34218 8.11331 6.34218C8.09492 6.34218 8.07041 6.34218 8.05202 6.34218C7.15693 6.31153 6.45801 5.5881 6.45801 4.68689C6.45801 3.7734 7.19983 3.03159 8.11331 3.03159C9.02679 3.03159 9.76862 3.7734 9.76862 4.68689C9.76249 5.5881 9.0636 6.31153 8.16851 6.34218C8.16851 6.34218 8.16237 6.34218 8.15623 6.34218ZM8.11331 3.9512C7.70869 3.9512 7.37762 4.28226 7.37762 4.68689C7.37762 5.08538 7.6903 5.41031 8.08267 5.42257C8.08267 5.41644 8.11946 5.41644 8.16237 5.42257C8.54861 5.39805 8.84901 5.07925 8.84901 4.68689C8.84901 4.28226 8.51794 3.9512 8.11331 3.9512Z" fill="#202020"/>
              <path d="M8.11235 10.6337C7.41344 10.6337 6.71455 10.4498 6.16891 10.0881C5.65393 9.74474 5.35352 9.24815 5.35352 8.72091C5.35352 8.19367 5.6478 7.69095 6.16891 7.34762C7.26019 6.6242 8.9645 6.6242 10.0496 7.34762C10.5646 7.69095 10.865 8.19367 10.865 8.71478C10.865 9.24202 10.5708 9.73861 10.0496 10.0881C9.51014 10.4559 8.81125 10.6337 8.11235 10.6337ZM6.67774 8.11397C6.41412 8.29176 6.27313 8.50633 6.27313 8.72091C6.27313 8.93549 6.42025 9.15006 6.67774 9.32785C7.45634 9.84897 8.76222 9.84897 9.54082 9.32785C9.80444 9.15006 9.95156 8.93549 9.94543 8.72091C9.94543 8.50633 9.79831 8.29176 9.54082 8.11397C8.76835 7.59285 7.45634 7.59285 6.67774 8.11397Z" fill="#202020"/>
              </svg>

              
         
            <span class="fontsize-small mr-1 ml-3"
              >
              
              گیرنده | {{ address.first_name }} {{ address.last_name }}
            </span>
            </span>
            <span class="d-inline-block">
              <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.3558 2.00793C14.1328 1.97595 13.9087 2.04191 13.7304 2.18381C13.5472 2.32771 13.4326 2.53557 13.4077 2.76841C13.355 3.23908 13.6946 3.66479 14.1646 3.71776C17.4063 4.07951 19.9259 6.60477 20.2904 9.85654C20.3392 10.2922 20.7047 10.621 21.1409 10.621C21.1738 10.621 21.2057 10.619 21.2385 10.615C21.4666 10.59 21.6697 10.4771 21.8132 10.2972C21.9556 10.1174 22.0203 9.89351 21.9944 9.66467C21.5403 5.60746 18.4002 2.45862 14.3558 2.00793ZM14.4181 5.48994C13.942 5.402 13.5048 5.70579 13.4142 6.17047C13.3236 6.63515 13.6283 7.08884 14.0914 7.17978C15.4857 7.45159 16.5623 8.53085 16.8351 9.92989V9.93089C16.9128 10.3336 17.2674 10.6264 17.6757 10.6264C17.7305 10.6264 17.7852 10.6214 17.841 10.6114C18.3041 10.5185 18.6088 10.0658 18.5182 9.60012C18.1109 7.51055 16.5025 5.89666 14.4181 5.48994ZM16.0001 15.0905C16.4515 14.8302 16.9615 14.5362 17.6047 14.673C18.1873 14.7959 20.1731 16.4078 20.7169 16.9665C21.0734 17.3322 21.2716 17.7099 21.3025 18.0877C21.3562 19.5697 19.3425 21.2615 18.976 21.4724C18.489 21.8231 17.9224 22 17.286 22C16.6357 22 15.9106 21.8151 15.1199 21.4464C10.8296 19.6556 4.27553 13.231 2.54266 8.97395C1.82362 7.38903 1.81864 6.08193 2.53071 5.09961C2.81454 4.63892 4.43288 2.71325 5.88192 2.7742C6.26733 2.80718 6.64179 3.00505 7.00928 3.3648C7.56499 3.90942 9.13653 5.90106 9.25803 6.48565C9.39247 7.13521 9.09769 7.65185 8.83576 8.10554C8.7799 8.20307 8.70463 8.31881 8.62217 8.4456C8.3092 8.92683 7.89264 9.56735 8.04004 9.97626C9.09669 12.5705 11.5376 14.8339 14.133 15.8972C14.5343 16.0431 15.1745 15.6235 15.6542 15.3092C15.7791 15.2273 15.8932 15.1525 15.9893 15.0967L16.0001 15.0905Z"
                fill="#777"
              />
            </svg>
         
            <span class="fontsize-small mr-1">
              شماره تماس | {{ address.mobile }}
            </span>
            </span>

          </div>
          <div v-if="address.postal_code" class="mr-1">
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.27303 11.0619C6.20559 11.0619 6.13202 11.0435 6.07071 11.0128C5.59865 10.7737 5.19402 10.4181 4.89974 9.97672C4.65451 9.60887 4.65451 9.13681 4.89974 8.76896C5.19402 8.32755 5.59865 7.97196 6.07071 7.739C6.29755 7.62251 6.57343 7.7206 6.68992 7.94744C6.8064 8.17428 6.71444 8.45016 6.48147 8.56665C6.15041 8.73218 5.8684 8.98354 5.66608 9.29007C5.6293 9.34525 5.6293 9.41882 5.66608 9.48013C5.8684 9.78666 6.15041 10.038 6.48147 10.2036C6.70831 10.32 6.80027 10.5959 6.68992 10.8228C6.60409 10.9699 6.43856 11.0619 6.27303 11.0619Z" fill="#202020"/>
              <path d="M10.0803 11.0618C9.90864 11.0618 9.74924 10.9699 9.66954 10.8105C9.55306 10.5836 9.64502 10.3077 9.87799 10.1913C10.209 10.0257 10.4911 9.77437 10.6934 9.46783C10.7302 9.41265 10.7302 9.33908 10.6934 9.27778C10.4911 8.97124 10.209 8.71988 9.87799 8.55435C9.65115 8.43787 9.55919 8.16198 9.66954 7.93514C9.78603 7.70831 10.0619 7.61635 10.2887 7.7267C10.7608 7.9658 11.1654 8.32138 11.4597 8.76279C11.7049 9.13064 11.7049 9.60271 11.4597 9.97055C11.1654 10.412 10.7608 10.7675 10.2887 11.0005C10.2152 11.0434 10.1477 11.0618 10.0803 11.0618Z" fill="#202020"/>
              <path d="M9.95125 14.1272H6.27281C2.94382 14.1272 1.52148 12.7049 1.52148 9.37587V5.69743C1.52148 2.36844 2.94382 0.946106 6.27281 0.946106H9.95125C13.2802 0.946106 14.7026 2.36844 14.7026 5.69743V9.37587C14.7026 12.7049 13.2802 14.1272 9.95125 14.1272ZM6.27281 1.86572C3.44654 1.86572 2.4411 2.87116 2.4411 5.69743V9.37587C2.4411 12.2021 3.44654 13.2076 6.27281 13.2076H9.95125C12.7775 13.2076 13.783 12.2021 13.783 9.37587V5.69743C13.783 2.87116 12.7775 1.86572 9.95125 1.86572H6.27281Z" fill="#202020"/>
              <path d="M2.12289 5.55028C1.87153 5.55028 1.66309 5.34184 1.66309 5.09048C1.66309 4.83912 1.8654 4.63067 2.12289 4.63067L13.9062 4.62454C14.1575 4.62454 14.366 4.83299 14.366 5.08435C14.366 5.33571 14.1637 5.54415 13.9062 5.54415L2.12289 5.55028Z" fill="#202020"/>
              </svg>
              
            <!-- <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.36922 10.869C7.42572 10.811 7.63906 10.563 7.8378 10.359C9.00292 9.076 12.0424 6.976 13.6332 6.335C13.8748 6.232 14.4856 6.014 14.812 6C15.1247 6 15.4228 6.072 15.7073 6.218C16.0619 6.422 16.3463 6.743 16.5022 7.122C16.6025 7.385 16.7584 8.172 16.7584 8.186C16.9143 9.047 17 10.446 17 11.992C17 13.465 16.9143 14.807 16.7867 15.681C16.772 15.695 16.6162 16.673 16.4457 17.008C16.133 17.62 15.5222 18 14.8685 18H14.812C14.3863 17.985 13.491 17.605 13.491 17.591C11.9859 16.949 9.01656 14.952 7.82319 13.625C7.82319 13.625 7.48709 13.284 7.34096 13.071C7.11301 12.765 7 12.386 7 12.007C7 11.584 7.12762 11.19 7.36922 10.869Z"
                fill="#777"
              />
            </svg> -->

            <span class="fontsize-small mr-1"
              >
              کدپستی | {{ address.postal_code }}</span
            >
          </div>
        </div>
        </div>
      </div>

      <div class="align-self-center  d-flex flex-column">
        <button @click="deleteAddress" class="address-edit-btn pr-1 pl-2 bg-color-brown rounded  mb-2 not-in-address">
          <svg
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.2871 5.24297C20.6761 5.24297 21 5.56596 21 5.97696V6.35696C21 6.75795 20.6761 7.09095 20.2871 7.09095H3.71385C3.32386 7.09095 3 6.75795 3 6.35696V5.97696C3 5.56596 3.32386 5.24297 3.71385 5.24297H6.62957C7.22185 5.24297 7.7373 4.82197 7.87054 4.22798L8.02323 3.54598C8.26054 2.61699 9.0415 2 9.93527 2H14.0647C14.9488 2 15.7385 2.61699 15.967 3.49699L16.1304 4.22698C16.2627 4.82197 16.7781 5.24297 17.3714 5.24297H20.2871ZM18.8058 19.134C19.1102 16.2971 19.6432 9.55712 19.6432 9.48913C19.6626 9.28313 19.5955 9.08813 19.4623 8.93113C19.3193 8.78413 19.1384 8.69713 18.9391 8.69713H5.06852C4.86818 8.69713 4.67756 8.78413 4.54529 8.93113C4.41108 9.08813 4.34494 9.28313 4.35467 9.48913C4.35646 9.50162 4.37558 9.73903 4.40755 10.1359C4.54958 11.8992 4.94517 16.8102 5.20079 19.134C5.38168 20.846 6.50498 21.922 8.13206 21.961C9.38763 21.99 10.6811 22 12.0038 22C13.2496 22 14.5149 21.99 15.8094 21.961C17.4929 21.932 18.6152 20.875 18.8058 19.134Z"
              fill="#fff"
            />
          </svg>
          <span class="address-btn-title  text-color-white">
            حذف آدرس
          </span>
        </button>
        <button
          @click="editAddress"
          :disabled="disabled"
          class="address-del-btn px-1 rounded bg-color-555 not-in-address"
        >
        <svg
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.3764 20.0279L18.1628 8.66544C18.6403 8.0527 18.8101 7.3443 18.6509 6.62299C18.513 5.96726 18.1097 5.34377 17.5049 4.87078L16.0299 3.69906C14.7459 2.67784 13.1541 2.78534 12.2415 3.95706L11.2546 5.23735C11.1273 5.39752 11.1591 5.63401 11.3183 5.76301C11.3183 5.76301 13.812 7.76246 13.8651 7.80546C14.0349 7.96671 14.1622 8.1817 14.1941 8.43969C14.2471 8.94493 13.8969 9.41792 13.377 9.48242C13.1329 9.51467 12.8994 9.43942 12.7297 9.29967L10.1086 7.21422C9.98126 7.11855 9.79025 7.13898 9.68413 7.26797L3.45514 15.3303C3.0519 15.8355 2.91395 16.4912 3.0519 17.1255L3.84777 20.5761C3.89021 20.7589 4.04939 20.8879 4.24039 20.8879L7.74222 20.8449C8.37891 20.8341 8.97316 20.5439 9.3764 20.0279ZM14.2797 18.9533H19.9898C20.5469 18.9533 21 19.4123 21 19.9766C21 20.5421 20.5469 21 19.9898 21H14.2797C13.7226 21 13.2695 20.5421 13.2695 19.9766C13.2695 19.4123 13.7226 18.9533 14.2797 18.9533Z"
              fill="#fff"
            />
          </svg>
          <span class="address-btn-title text-color-white">
            تغییر آدرس
          </span>
        </button>
      </div>
    </div>
  </article>
</template>
<script>
export default {
  props: ["disabled", "address", "selectedAddress", "index"],
  data() {
    return {

    };
  },
  watch: {
  },
  methods:{
    deleteAddress() {
      this.$emit("delete-address");
    },
    editAddress() {
      this.$emit("edit-address");
    },
  }
};
</script>
<style scoped>
.address-row2{
  align-items: center;
}
.selected-radio{
  border-radius: 50%;
}
.address-edit-btn,
.address-del-btn {
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px !important;


}
@media screen and (max-width:768px) {
  .address-btn-title{
    display: none;
  }
  .address-edit-btn,  .address-del-btn {
    width: 24px;
    height: 24px;
  }
  .address-row2 , .address-row2 div:nth-child(1){
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  .address-row2 div:nth-child(1){
    gap: .5rem;
  }
  .address-row2 div:nth-child(2){
    margin-right: 0px !important;
  }
}
</style>
