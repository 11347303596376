<template>
  <div class="container" v-if="content != null">
    <PagesPath :pathTitle1="'تماس با ما'" :pathTo1="'contact'"></PagesPath>
    <div class="">
      <section class="contact p-3">
        <div class="bg-color-theme text-color-white border-r-30 p-3">
          <div class="pt-4 pb-4">
            <h2 class="font-bold font-w-900">ارتباط با مشتریان</h2>
            <p class="font-w-400 line-height2 font-s-14">
              {{ content.settings.site.contact_message }}            
            </p>
          </div>
        </div>
        <section
          class="contact-info container flex-wrap p-md-4 p-3 mt-3 d-flex align-items-center border-r-30 box-shaddow20 bg-white mb-5 flex-wrap"
        >
          <div class="d-flex align-items-center gap-10">
            <svg
              width="30"
              height="30"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 17.5C0 7.83502 7.83502 0 17.5 0C27.165 0 35 7.83502 35 17.5C35 27.165 27.165 35 17.5 35H5C2.23858 35 0 32.7614 0 30V17.5Z"
                fill="#F172AC"
              />
              <path
                d="M24.3087 23.275C24.3087 23.575 24.242 23.8833 24.1003 24.1833C23.9587 24.4833 23.7753 24.7667 23.5337 25.0333C23.1253 25.4833 22.6753 25.8083 22.167 26.0167C21.667 26.225 21.1253 26.3333 20.542 26.3333C19.692 26.3333 18.7837 26.1333 17.8253 25.725C16.867 25.3167 15.9087 24.7667 14.9587 24.075C14.0003 23.375 13.092 22.6 12.2253 21.7417C11.367 20.875 10.592 19.9667 9.90033 19.0167C9.21699 18.0667 8.66699 17.1167 8.26699 16.175C7.86699 15.225 7.66699 14.3167 7.66699 13.45C7.66699 12.8833 7.76699 12.3417 7.96699 11.8417C8.16699 11.3333 8.48366 10.8667 8.92533 10.45C9.45866 9.92499 10.042 9.66666 10.6587 9.66666C10.892 9.66666 11.1253 9.71666 11.3337 9.81666C11.5503 9.91666 11.742 10.0667 11.892 10.2833L13.8253 13.0083C13.9753 13.2167 14.0837 13.4083 14.1587 13.5917C14.2337 13.7667 14.2753 13.9417 14.2753 14.1C14.2753 14.3 14.217 14.5 14.1003 14.6917C13.992 14.8833 13.8337 15.0833 13.6337 15.2833L13.0003 15.9417C12.9087 16.0333 12.867 16.1417 12.867 16.275C12.867 16.3417 12.8753 16.4 12.892 16.4667C12.917 16.5333 12.942 16.5833 12.9587 16.6333C13.1087 16.9083 13.367 17.2667 13.7337 17.7C14.1087 18.1333 14.5087 18.575 14.942 19.0167C15.392 19.4583 15.8253 19.8667 16.267 20.2417C16.7003 20.6083 17.0587 20.8583 17.342 21.0083C17.3837 21.025 17.4337 21.05 17.492 21.075C17.5587 21.1 17.6253 21.1083 17.7003 21.1083C17.842 21.1083 17.9503 21.0583 18.042 20.9667L18.6753 20.3417C18.8837 20.1333 19.0837 19.975 19.2753 19.875C19.467 19.7583 19.6587 19.7 19.867 19.7C20.0253 19.7 20.192 19.7333 20.3753 19.8083C20.5587 19.8833 20.7503 19.9917 20.9587 20.1333L23.717 22.0917C23.9337 22.2417 24.0837 22.4167 24.1753 22.625C24.2587 22.8333 24.3087 23.0417 24.3087 23.275Z"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
              />
              <path
                d="M21.4167 15.5C21.4167 15 21.025 14.2333 20.4417 13.6083C19.9083 13.0333 19.2 12.5833 18.5 12.5833"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M24.3333 15.5C24.3333 12.275 21.725 9.66666 18.5 9.66666"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <h5 class="text-color-theme font-w-900 font-s-18 mb-0">
              اطلاعات تماس :
            </h5>
          </div>
          <div class="d-flex align-items-center gap-10 flex-wrap">
            <div
              class="p-3 fontsize15 d-flex flex-wrap justify-content-between align-items-center gap-10"
            >
              <div>
                <span class="font-w-900 font-s-14 text-color-444">تلفن :</span>
              </div>
              <div class="">
                <span
                  style="word-spacing: 10px"
                  class="ml-2 font-w-300 font-s-14 text-color-666"
                  >{{ content.settings.site.mobile }}</span
                >
              </div>
            </div>
            <div
              class="p-3 fontsize15 d-flex flex-wrap justify-content-between align-items-baseline"
            >
              <div>
                <span class="font-w-900 font-s-14 text-color-444"
                  >پشتیبانی :
                </span>
              </div>
              <div class="">
                <span class="ml-2 font-w-300 font-s-14 text-color-666">{{
                  content.settings.site.mobile_support
                }}</span>
              </div>
            </div>
            <div
              class="p-3 fontsize15 d-flex flex-wrap justify-content-between align-items-baseline"
            >
              <div>
                <span class="font-w-900 font-s-14 text-color-444"
                  >ایمیل :
                </span>
              </div>
              <div class="">
                <span class="ml-2 font-w-300 font-s-14 text-color-666">{{
                  content.settings.site.email
                }}</span>
              </div>
            </div>
          </div>
          <div
            class="pt-3 pb-3 fontsize15 d-flex flex-wrap justify-content-between align-items-center gap-10 address-contact"
          >
            <div class="d-flex align-items-center gap-10">
              <svg
                width="30"
                height="30"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 17.5C0 7.83502 7.83502 0 17.5 0C27.165 0 35 7.83502 35 17.5C35 27.165 27.165 35 17.5 35H5C2.23858 35 0 32.7614 0 30V17.5Z"
                  fill="#F172AC"
                />
                <path
                  d="M17.0004 19.1917C18.4363 19.1917 19.6004 18.0276 19.6004 16.5917C19.6004 15.1557 18.4363 13.9917 17.0004 13.9917C15.5645 13.9917 14.4004 15.1557 14.4004 16.5917C14.4004 18.0276 15.5645 19.1917 17.0004 19.1917Z"
                  stroke="white"
                  stroke-width="1.5"
                />
                <path
                  d="M10.0167 15.075C11.6584 7.85833 22.3501 7.86666 23.9834 15.0833C24.9418 19.3167 22.3084 22.9 20.0001 25.1167C18.3251 26.7333 15.6751 26.7333 13.9918 25.1167C11.6918 22.9 9.05842 19.3083 10.0167 15.075Z"
                  stroke="white"
                  stroke-width="1.5"
                />
              </svg>

              <h5 class="text-color-theme font-w-900 font-s-18 mb-0">آدرس :</h5>
              <!-- <ZTwoArrowSvg /> -->
            </div>
            <div class="">
              <span class="ml-2 font-w-300 font-s-14 text-color-666"
                >{{ content.settings.site.address }}
              </span>
            </div>
          </div>
        </section>
        <div class="box-shaddow20 bg-white container p-3 border-r-30">
          <div class="d-flex align-items-center p-3 gap-10">
            <span>
              <svg
                width="25"
                height="25"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.3652 2.2583C14.3786 2.2583 15.3535 2.65884 16.0707 3.37755C16.7887 4.09475 17.19 5.0621 17.19 6.07479V12.0451C17.19 14.1536 15.4745 15.8616 13.3652 15.8616H5.89924C3.78997 15.8616 2.0752 14.1536 2.0752 12.0451V6.07479C2.0752 3.96627 3.78241 2.2583 5.89924 2.2583H13.3652ZM14.5676 7.20084L14.628 7.14038C14.8087 6.92122 14.8087 6.6038 14.6197 6.38464C14.5147 6.27203 14.3703 6.20326 14.2199 6.18815C14.0612 6.17983 13.9101 6.23349 13.796 6.33929L10.3883 9.05996C9.95 9.42347 9.32198 9.42347 8.87685 9.05996L5.47602 6.33929C5.24099 6.16547 4.91602 6.18815 4.72028 6.3922C4.51623 6.59625 4.49356 6.92122 4.66663 7.14794L4.76563 7.24618L8.20425 9.92906C8.62746 10.2616 9.14061 10.443 9.67794 10.443C10.2138 10.443 10.736 10.2616 11.1584 9.92906L14.5676 7.20084Z"
                  fill="#F172AC"
                />
              </svg>
            </span>
            <h5 class="font-w-900 font-s-20 text-color-theme mb-0">
              فرم درخواست پشتیبانی
            </h5>
          </div>
          <p class="line-height2 text-color-666 fontsize14 text-justify">
            {{ content.settings.site.about_us_site }}
          </p>
          <template v-if="isLogin">
            <form class="p-3">
              <div class="contact-grid mb-3 fontsize14">
                <input
                  class="bg-color-fa border-r-15 p-3"
                  ref="name"
                  type="text"
                  placeholder="نام و نام خانوادگی:"
                />
                <input
                  class="bg-color-fa border-r-15 p-3"
                  ref="phone"
                  :type="mediaQueries.mobileSize == true ? 'tel' : 'number'"
                  placeholder="شماره تماس:"
                />
              </div>
              <div class="contact-grid fontsize14">
                <!-- email -->
                <input
                  class="bg-color-fa border-r-15 p-3"
                  ref="email"
                  type="email"
                  name="email"
                  placeholder="ایمیل :"
                />
                <input
                  class="bg-color-fa border-r-15 p-3"
                  ref="subject"
                  type="text"
                  placeholder="موضوع:"
                />
              </div>
              <textarea
                class="bg-color-fa border-r-15 p-3 w-100 mt-3"
                ref="body"
                id=""
                cols="30"
                rows="10"
                placeholder="پیام:"
              ></textarea>
              <div
                id="contactCheckAndSubmit"
                class="d-flex justify-content-between align-items-baseline mt-3 fontsize14"
              >
                <div
                  v-if="question != null"
                  class="d-flex align-items-center mb-sm-4 contact-text security-question"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.9999 2.75021C17.1089 2.75021 21.2499 6.89221 21.2499 12.0002C21.2499 17.1082 17.1089 21.2502 11.9999 21.2502C6.89188 21.2502 2.74988 17.1082 2.74988 12.0002C2.74988 6.89221 6.89188 2.75021 11.9999 2.75021Z"
                      stroke="#999"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.995 8.20432V12.6233"
                      stroke="#999"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.995 15.7961H12.005"
                      stroke="#999"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span class="fontsize14 text-color-999 mr-1">
                    {{ question }}
                  </span>
                  <input
                    id="answerBox"
                    ref="answer"
                    class="bg-color-fa mr-2 border-r-15 border p-3"
                    type="text"
                  />
                </div>
                <input
                  class="w-25 send-message bg-brown-theme p-3 text-white"
                  :class="{ disabled: disabled }"
                  @click.prevent="sendForm"
                  type="submit"
                  value="ارسال پیام"
                />
              </div>
            </form>
          </template>
          <template v-else-if="!isLogin">
            <span class="notLoginMassege"
              >برای ارسال پیام ابتدا به حساب کاربری خود وارد شوید !</span
            >
          </template>
        </div>
      </section>
      <div class="map container mb-5">
        <iframe
          class="w-100 border-r-30 box-shaddow20"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3193.699034231972!2d54.454631199999994!3d36.8257313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f83358d5f7be133%3A0xf8eddbc570992da7!2sBenedito%20Oil%20shop!5e0!3m2!1sen!2s!4v1705387694689!5m2!1sen!2s"
          height="450"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import PagesPath from "@/parts/Front/components/PagesPath";
import ZTwoArrowSvg from "../components/shared/ZTwoArrowSvg";
import SocialNetworks from "../components/shared/SocialNetworks";
export default {
  name: "Contact",
  inject: ["mediaQueries"],
  components: {
    SocialNetworks,
    ZTwoArrowSvg,
    PagesPath,
  },
  data() {
    return {
      code: null,
      question: null,
      disabled: false,
    };
  },
  computed: {
    faqs() {
      return this.$store.getters["front/getFaqList"];
    },
    // ront/getAllFaqListFromServer

    // دریافت محتوای درخواست هوم
    content() {
      return this.$store.getters["front/getHomeData"];
    },
    loading() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
    user() {
      if (
        this.$store.getters["front/getHomeData"] != null &&
        this.$store.getters["front/getHomeData"].user.user != null
      ) {
        return this.$store.getters["front/getHomeData"].user.user;
      } else {
        return null;
      }
    },
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
  },
  mounted() {
    if (!this.faqs) {
      this.$store.dispatch("front/getAllFaqListFromServer");
    }
    this.$axios.get("all/contacts/create").then((response) => {
      this.code = response.data.data.name;
      this.question = response.data.data.message;
    });
  },
  methods: {
    sendForm() {
      // if (this.$refs.name.value.length < 2) {
      //   this.$refs.name.classList.add("inputError");
      // return   window.swal({
      //     title: "وارد کردن  نام الزامی است",
      //     text: "نام باید شامل دو کارکتر یا بیشتر باشد",
      //     icon: "warning",

      //     showConfirmButton: true,
      //     dangerMode: true,
      //     button: "متوجه شدم",
      //   }).then(()=>{

      //     this.$refs.name.focus();
      //   })

      // } else {
      //   this.$refs.name.classList.remove("inputError");
      // }
      // if (this.$refs.phone.value.length != 11) {
      //   this.$refs.phone.classList.add("inputError");
      //  return  window.swal({
      //     title: "ورود کردن شماره تماس الزامی است",
      //     text: "شماره تماس شامل 11 رقم است",
      //     icon: "warning",

      //     showConfirmButton: true,
      //     dangerMode: true,
      //               button: "متوجه شدم",

      //   }).then(()=>{

      //     this.$refs.phone.focus();
      //   })

      // } else {
      //   this.$refs.phone.classList.remove("inputError");
      // }
      if (this.$refs.subject.value.length < 3) {
        this.$refs.subject.classList.add("inputError");
        return window
          .swal({
            title: "موضوع ارتباط را به درستی وارد کنید",
            icon: "warning",

            showConfirmButton: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.subject.focus();
          });
      } else {
        this.$refs.subject.classList.remove("inputError");
      }
      if (this.$refs.body.value.length < 3) {
        this.$refs.body.classList.add("inputError");
        return window
          .swal({
            title: "متن پیام خود را وارد کنید",
            text: "این متن نباید از 10 کارکتر کمتر باشد",
            icon: "warning",

            showConfirmButton: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.body.focus();
          });
      } else {
        this.$refs.body.classList.remove("inputError");
      }
      if (this.$refs.answer.value.length < 1) {
        this.$refs.answer.classList.add("inputError");
        return window
          .swal({
            title: "پاسخ سوال امنیتی را وارد کنید",
            icon: "warning",

            showConfirmButton: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.answer.focus();
          });
      } else {
        this.$refs.answer.classList.remove("inputError");
      }
      const formData = new FormData();
      // formData.append("name", this.$refs.name.value);
      // formData.append("phone_number", this.$refs.phone.value);
      formData.append("subject", this.$refs.subject.value);
      formData.append("body", this.$refs.body.value);
      formData.append("customer_id", this.user.id);
      formData.append("" + this.code, this.$refs.answer.value);
      this.disabled = true;
      this.$axios
        .post("/customer/contacts", formData)
        .then((res) => {
          this.disabled = false;
          // this.$refs.name.value = null;
          // this.$refs.phone.value = null;
          this.$refs.subject.value = null;
          this.$refs.body.value = null;
          this.$refs.answer.value = null;
          window.swal({
            title: "ارتباط با فروشگاه بندیتو برقرار شد",
            text: "پیام شما در سیستم ما درج و به زودی پاسخ آنرا دریافت خواهید کرد باتشکر!",
            icon: "success",

            showConfirmButton: true,
            button: "متوجه شدم",
          });
        })
        .catch((err) => {
          this.disabled = false;
          window
            .swal({
              title: "پاسخ سوال امنیتی را  به درستی وارد نکرده اید",
              icon: "warning",

              showConfirmButton: true,
              dangerMode: true,
              button: "متوجه شدم",
            })
            .then(() => {
              this.$refs.answer.focus();
            });
        });
    },
  },
};
</script>

<style scoped>
/* برداشتن دکمه های بالا پایین برای ورود عدد */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
#answerBox {
  padding: 5px !important;
  width: 70px;
  border-radius: 4px;
}
#contactCheckAndSubmit {
  display: flex;
  flex-wrap: wrap;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.inputError {
  border: 1px solid red !important;
  background: #efe1e4;
}
span.notLoginMassege {
  font-size: 17px;
  background-color: #cccccc3f;
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
  margin-top: 1rem;
  display: table;
}
.faqs details summary div {
  background: var(--color-themepinky);
  width: 24px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  height: 24px;
  align-items: center;
  color: white;
}
.faqs details summary {
  display: flex;
  gap: 12px;
  align-items: center;
}
.faqs details[open] summary .o {
  display: block;
}
.faqs details[open] summary .c {
  display: none;
}
.faqs details summary .o {
  display: none;
}
.faqs details {
  margin: 16px;
}
.faqs p {
  margin-top: 24px;
  margin-right: 5%;
  padding: 12px;
  box-shadow: 0 0 5px #eb6fa7;
}
.send-message {
  border-radius: 15px;
}
@media screen and (max-width: 578px) {
  .security-question {
    margin-bottom: 1.5rem;
  }
}
</style>
