<template>
  <section v-if="faqs && faqs.length" class="container mt-5">
    <div class="white-bg">
      <ul>
        <li
          v-for="(item, index) in faqs"
          :key="index"
          class="border-r-20 px-3 py-2 px-md-4 py-md-3 mb-2"
          v-b-toggle="'faq' + index"
        >
          <div class="d-flex align-items-baseline justify-content-between">
            <p class="m-0 font-w-700 fontsize-medium">{{ item.question }}</p>
            <button class="m-1 rounded-circle px-0 py-0 px-md-2 py-md-2 border toggle-btn">
              <ToggleIcon />
            </button>
          </div>
          <BCollapse :id="`faq${index}`">
            <p class="m-0 pr-3 py-3 fontsize-mini">
              {{ item.answer }}
            </p>
          </BCollapse>
        </li>
      </ul>
    </div>
  </section>
</template>
<script>
import { BCollapse, VBToggle } from "bootstrap-vue";
import ToggleIcon from "../../Front/components/Icon/Toggle.vue";
export default {
  components: {
    BCollapse,
    ToggleIcon,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  computed: {
    faqs() {
      return this.$store.getters["front/getFaqList"];
    },
  },
  mounted() {
    if (!this.faqs) {
      this.$store.dispatch("front/getAllFaqListFromServer");
    }
  },
};
</script>
<style scoped>
ul li {
  list-style-type: none;
  background-color: #f3f3f3;
}
.not-collapsed {
  background-color: var(--color-main) !important;
  color: #ffffff;
}
.toggle-btn {
  background-color: #f3f3f3;
}
.toggle-btn {
  transition: all 0.4s ease-in-out;
}
.not-collapsed .toggle-btn {
  transform: rotate(225deg);
}
</style>
