<template>
  <div id="orderBox">
    <PagesPath :pathTitle1="'سفارش'" />
    <template v-if="status">
      <div style="margin: 5%">
        <b-skeleton type="input" />
        <hr />
        <b-skeleton />
        <hr />
        <b-skeleton />
        <hr />
        <b-skeleton />
        <hr />
        <b-skeleton />
      </div>
    </template>
    <template v-else>
      <div id="orderSection">
        <section @click="closeModal" id="editAddressModal" v-if="addressModal">
          <div id="content">
            <ul v-if="homeData && homeData.user">
            <li style="margin-bottom:24px">
              <h4>
                {{`ثبت آدرس جديد براي سفارش : ${order.order.id}`}}
              </h4>
            </li>
              <li
                v-for="address in homeData.user.user.addresses"
                :key="address.id"
              >
                <p class="addresses" @click="selectedAddress=address.id">
                  <span
                    :class="{ selectedAddress: selectedAddress == address.id }"
                    class="selectBox"
                  ></span>
                  <span class="userAddress">
                    {{
                      `${address.first_name} ${address.last_name} - ${address.city.province.name} ${address.city.name} - ${address.address}`
                    }}
                  </span>
                </p>
              </li>
              <li>
                <button @click="editAddress()">
                  ثبت آدرس
                </button>
              </li>
            </ul>
            <p v-else>
              لطفا صفحه را مجددا بروزرسانی کنید و مجددا تلاش بفرمایید
            </p>
          </div>
        </section>
        <div class="showInPrint" id="headerInPrint">
          <img src="@/assets/images/logo.png" alt="neek" />
          <span>فاکتور خرید کالا از فروشگاه بندیتو</span>
          <span>کد سفارش:{{ " " + $route.params.id }}</span>
        </div>
        <div class="showInPrint" id="userInformation">
          <table v-if="user != null" id="customerTabel">
            <tbody>
              <tr>
                <th class="markCellHeader" scope="row" colspan="5">
                  مشخصات خریدار
                </th>
              </tr>
              <tr>
                <th scope="row" class="markCell">مشتری:</th>
                <td colspan="2" class="col_2">{{ user.full_name }}</td>
                <td class="markCell">شماره تماس :</td>
                <td scope="row" class="last_col">{{ user.mobile }}</td>
              </tr>
              <tr>
                <th scope="row" class="markCell">کد ملی:</th>
                <td colspan="2" class="col_2">{{ user.national_code }}</td>
                <td class="markCell">کد پستی :</td>
                <td scope="row" class="last_col">
                  {{ getCustomerAdderss().postal_code }}
                </td>
              </tr>

              <tr>
                <th scope="row" class="markCell">آدرس:</th>
                <td colspan="4">{{ getCustomerAdderss().address }}</td>
              </tr>
            </tbody>
          </table>

          <table id="atlasTabel" v-if="homeData != null">
            <tbody>
              <tr>
                <th class="markCellHeader" scope="row" colspan="5">
                  مشخصات فروشنده
                </th>
              </tr>
              <tr>
                <th scope="row" class="markCell">فروشنده:</th>
                <td colspan="2" class="col_2">
                  {{ homeData.settings.shop_info.shop_name }}
                </td>
                <td class="markCell">شماره تماس :</td>
                <td scope="row" class="last_col">
                  {{ homeData.settings.shop_info.shop_telephone }}
                </td>
              </tr>
              <tr>
                <th scope="row" class="markCell">استان/شهر:</th>
                <td colspan="2" class="col_2">گلستان / گرگان</td>
                <td class="markCell">کد پستی :</td>
                <td scope="row" class="last_col">
                  {{ homeData.settings.shop_info.shop_postal_code }}
                </td>
              </tr>

              <tr>
                <th scope="row" class="markCell">آدرس:</th>
                <td colspan="4">
                  {{ homeData.settings.shop_info.shop_address }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div id="itemTable">
          <table class="table">
            <thead>
              <tr id="trItemTable" class="text-color-444 fontsize14">
                <th class="hideIn768" scope="col">شناسه</th>
                <th scope="col">تاریخ</th>
                <th scope="col">محصول</th>
                <th scope="col">قیمت اصلی</th>
                <th scope="col">تخفیف</th>
                <th scope="col">تعداد</th>
                <th scope="col">قیمت نهایی</th>
                <th
                  scope="col"
                  v-if="
                    order.order.status == 'new' && order.order.parent_id == null
                  "
                >
                  تغییرات
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="item in order.order.items">
                <tr
                 
                  :key="item.id"
                  :class="{

                    opacity05:checkStatus(order.order),
                    darkBack: item.canceled == 1,
                    canceled:
                      item.status == 0 ||
                      order.order.status == 'canceled_by_user',
                  }"
                >
                  <th class="hideIn768" scope="row">{{ item.id }}</th>
                  <td scope="row">
                    {{ moment(item.created_at).format("HH:mm YYYY/MM/DD") }}
                  </td>
                  <td>
                    {{ item.product.title + " " + getVarietyTitle(item.extra) }}
                  </td>
                  <td>
                    {{
                      getSumPrice(
                        item.amount,
                        item.discount_amount,
                        item.quantity
                      ) | price
                    }}
                    تومان
                  </td>
                  <td>
                    <template v-if="item.discount_amount == null">
                      ندارد
                    </template>
                    <template v-else>
                      {{ item.discount_amount | price }}
                    </template>
                  </td>
                  <td>{{ item.quantity }}</td>
                  <td>{{ item.amount | price }} تومان</td>
                  <td
                    v-if="
                      order.order.status == 'new' &&
                        order.order.parent_id == null
                    "
                  >
                  <!--  && item.discount_amount == 0 -->
                    <button
                      v-if="item.status == 1"
                      @click="canceleOrderItem(item.id)"
                      class="canceleOrder"
                      :class="{ disableButton: !canceleButtonStatus }"
                    >
                      لغو
                    </button>
<!-- 
                    <span
                      v-else-if="
                        item.discount_amount != 0 || item.flash != null
                      "
                      >امکان لغو وجود ندارد</span
                    > -->
                    <span v-else-if="item.status == 0">لغو شده</span>
                  </td>
                </tr>
              </template>

              <template v-for="(childs, index) in order.childs">
                <template v-for="(childItem, index2) in childs.items">
                  <tr
                    :key="index2 + index"
                    v-if="checkStatus(childs)"
                    :class="{
                      darkBack: childItem.canceled == 1,
                      canceled:
                        childItem.status == 0 ||
                        childs.status == 'canceled_by_user',
                    }"
                  >
                    <th class="hideIn768" scope="row">{{ childItem.id }}</th>
                    <td scope="row">
                      {{
                        moment(childItem.created_at).format("HH:mm YYYY/MM/DD")
                      }}
                    </td>
                    <td>
                      {{
                        childItem.product.title +
                          " " +
                          getVarietyTitle(childItem.extra)
                      }}
                    </td>
                    <td>
                      {{
                        getSumPrice(
                          childItem.amount,
                          childItem.discount_amount,
                          childItem.quantity
                        ) | price
                      }}
                      تومان
                    </td>
                    <td>
                      <template v-if="childItem.discount_amount == null">
                        ندارد
                      </template>
                      <template v-else>
                        {{ childItem.discount_amount | price }}
                      </template>
                    </td>
                    <td>{{ childItem.quantity }}</td>
                    <td>{{ childItem.amount | price }} تومان</td>
                    <td
                      v-if="
                        childs.status == 'new' && order.order.parent_id == null
                      "
                    >
                    <!-- &&
                            childItem.discount_amount == 0 -->
                      <button
                        v-if="
                          childItem.status == 1
                        "
                        @click="canceleOrderItem(childItem.id)"
                        class="canceleOrder"
                        :class="{ disableButton: !canceleButtonStatus }"
                      >
                        لغو
                      </button>

                      <!-- <span
                        v-else-if="
                          childItem.discount_amount != 0 ||
                            childItem.flash != null 
                        "
                        >امکان لغو وجود ندارد</span
                      > -->
                      <span v-else-if="childItem.status == 0">لغو شده</span>
                    </td>
                  </tr>
                </template>
              </template>

              <template v-if="order.order.reservations.length != 0">
                <template v-for="reserv in order.order.reservations">
                  <tr v-for="item in reserv.items" :key="reserv.id + item.id">
                    <th class="hideIn768" scope="row">{{ item.id }}</th>
                    <td scope="row">
                      {{ moment(item.created_at).format("HH:mm YYYY/MM/DD") }}
                    </td>
                    <td style="min-width: 118px;">
                      {{
                        item.product.title + " " + getVarietyTitle(item.extra)
                      }}
                    </td>
                    <td>{{ item.amount | price }} تومان</td>
                    <td>
                      {{
                        item.discount_amount == null
                          ? " ندارد"
                          : item.discount_amount
                      }}
                    </td>
                    <td>{{ item.quantity }}</td>
                    <td>
                      {{
                        getSumPrice(
                          item.amount,
                          item.discount_amount,
                          item.quantity
                        ) | price
                      }}
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
        <div id="canceleOrder" class="canceleOrderHolder">
          <button
            v-if="order.order.status == 'new' && order.order.parent_id == null"
            class="mainCanceleOrder"
            @click="canceleOrder(order.order.id, true)"
            :class="{ disableButton: cancelOrderStatus }"
          >
            لغو کل سفارش
          </button>
        </div>
        <div
          id="separator"
          class="d-flex justify-content-between align-items-baseline my-5"
        >
          <b-skeleton type="button" v-if="status"></b-skeleton>
          <h6
            v-else
            class="text-color-444 weight-bold"
            style="white-space: nowrap;"
          >
            {{
              order.order.parent_id != null
                ? "مشاهده فاکتور اصلی"
                : "فاکتور ها برای سفارش :" + order.order.id
            }}
          </h6>
          <div class="line bg-color-c8 w-75"></div>
          <b-skeleton v-if="status" type="button"></b-skeleton>
          <router-link
            id="gotoOrginalOrder"
            :to="`/order/${order.order.parent_id}`"
            v-if="order.order.parent_id != null"
            >مشاهده فاکتور {{ order.order.parent_id }}</router-link
          >
          <button
            id="printBtn"
            v-else
            @click="printInvoices()"
            class="py-2 px-3 border-radius7 bg-white text-color-444 fontsize12 box-shaddow20"
          >
            چاپ
          </button>
          <button
            :class="{ notAllow: homeData == null }"
            id="editAddress"
            @click="addressModal = true"
            v-if="order.order.status == 'new'"
          >
            ویرایش آدرس
          </button>
        </div>
        <div id="sumInvoSec">
          <table class="showInPrint" id="sumInvoices">
            <tbody>
              <tr>
                <th scope="row" class="titleCell">مجموع تخفیفات</th>
                <td class="resultCell">
                  {{
                    order.order.discount_amount != 0
                      ? order.order.discount_amount
                      : "ندارد"
                  }}
                </td>
                <td class="titleCell">هزینه ارسال</td>
                <td class="resultCell">
                  {{
                    order.order.shipping_amount != 0
                      ? order.order.shipping_amount
                      : "رایگان"
                  }}
                </td>
              </tr>

              <tr>
                <th scope="row" class="titleCell">جمع کل :</th>
                <td class="resultCell">
                  {{ order.order.total_amount + " " }} تومان
                </td>
                <td class="titleCell">نحوه ارسال</td>
                <td class="resultCell">{{ order.order.shipping.name }}</td>
              </tr>

              <tr>
                <th scope="row" class="titleCell">نحوه پرداخت</th>
                <template v-for="item in order.order.invoices">
                  <td
                    v-if="item.status == 'success'"
                    :key="item.id"
                    class="resultCell"
                  >
                    {{ findPayments(item.type, item.payments[0]) }}
                  </td>
                </template>
                <td class="titleCell">تاریخ سفارش</td>
                <td class="resultCell">
                  {{
                    moment(order.order.created_at).format("HH:mm YYYY/MM/DD")
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div id="invoicesTable" v-if="order.order.parent_id == null">
          <table id="invoices" class="table">
            <thead>
              <tr id="trInvoicesHead" class="text-color-444 fontsize14">
                <th scope="col" class="hideIn768">شناسه</th>
                <!-- <th scope="col">کدرهگیری</th> -->
                <th scope="col">تاریخ</th>
                <th scope="col">قیمت</th>
                <th scope="col">وضعیت</th>
                <th scope="col">نحوه پرداخت</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, index) in order.order.invoices">
                <tr v-if="item.status == 'success'" :key="item.id">
                  <th class="hideIn768" scope="row">{{ index + 1 }}</th>
                  <!-- <td scope="row">
                    {{
                      item.payments.length != 0
                        ? item.payments[0].tracking_code
                        : "ندارد"
                    }}
                  </td> -->
                  <td>
                    {{ moment(item.created_at).format("HH:mm YYYY/MM/DD") }}
                  </td>
                  <td>{{ item.amount | price }} تومان</td>
                  <td>{{ getInvoicesStatus(item.status) }}</td>
                  <td>{{ findPayments(item.type, item.payments[0]) }}</td>
                </tr>
              </template>

              <template v-for="(childs, index) in order.childs">
                <template v-for="childItem in childs.invoices">
                  <tr v-if="childItem.status == 'success'" :key="childItem.id">
                    <th class="hideIn768" scope="row">{{ index + 1 }}</th>
                    <td scope="row">
                      {{
                        childItem.payments.length != 0
                          ? childItem.payments[0].tracking_code
                          : "ندارد"
                      }}
                    </td>
                    <td>
                      {{
                        moment(childItem.created_at).format("HH:mm YYYY/MM/DD")
                      }}
                    </td>
                    <td>{{ childItem.amount | price }} تومان</td>
                    <td>{{ getInvoicesStatus(childItem.status) }}</td>
                    <td>
                      {{ findPayments(childItem.type, childItem.payments[0]) }}
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>

          <ul class="showInPrint">
            <span><b> شرایط ارسال دوباره محصول از طرف فروشگاه :</b></span>
            <li>اگر محصولی که به دستتون رسید ، نشت کرده و یا شکسته شده بود</li>
            <li>اگر محصولی که به دستتون رسیده با عکسی که داخل سایت هست متفاوت بود</li>
            <li>اگر به اشتباه روغن دیگری برای شما ارسال شده بود</li>
            <p>با این شرایط  فروشگاه با هزینه خودش دوباره برای شما محصول را ارسال میکند</p>
          </ul>
          <ul class="showInPrint">
            <span><b> شرایط مرجوعی کالا :</b></span>
          <li>اگر بعد از تست حساسیت به محصول آلرژی داشتید</li>
          <li>اگر به هر دلیلی، دیگر نیازی به محصول خریداری شده نداشتید</li>
          <p>ظرف مدت 7 روز زمان دارید که محصول رو با هزینه خودتون برای ما ارسال کنید و عین مبلغ پرداختی شما به شما برخواهد گشت</p>
          </ul>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import product from "@/parts/Front/components/productDetail/classes/product";
import PagesPath from "@/parts/Front/components/PagesPath";
import { Printd } from "printd";
import {
  findPayments,
  findPriceOrder,
  getOrderStatus,
  getInvoicesStatus,
  getSumPrice,
} from "@/libraries/functions";
import { BSkeleton } from "bootstrap-vue";

export default {
  data() {
    return {
      selectedAddress:null,
      canceleButtonStatus: true,
      cancelOrderStatus: false,
      addressModal: false,
    };
  },
  created() {
    if (this.user == null) {
      this.$store.dispatch("front/getUserDetailFromServer");
    }
  },
  methods: {
    editAddress(){
      const fd=new FormData();
      fd.append("address_id",this.selectedAddress);
      fd.append("_method","put")
      this.$axios.post(`/customer/orders/${this.order.order.id}/updateAddress`,fd).then(res=>{
        window.swal({
            title: "آدرس شما با موفقیت ويرايش گردید",
            icon: "success",
            timer: 2500,
          });
      })
      this.addressModal=false;
    },
    
    checkStatus(order) {
      let failed =[
        'canceled',
        'failed',
        'canceled_by_user',
        'wait_for_payment'
		];
      return failed.includes(order.status);
    },
    canceleOrderRequest(id) {
      const formData = new FormData();
      this.cancelOrderStatus = true;
      formData.append("status", "canceled_by_user");
      this.$axios
        .post(`customer/orders/${id}/cancelOrder`, formData)
        .then(() => {
          window.swal({
            title: "سفارش شما با موفقیت لغو گردید",
            icon: "success",
            timer: 1500,
          });
          this.getOrder();
        })
        .catch((error) => {
          this.cancelOrderStatus = false;

          this.$root.notify(error.response.data.message, "warning");
        });
    },
    canceleOrder(id, status) {
      if (status) {
        window
          .swal({
            title: "در صورت تایید کل سفارش شما لغو میگردد",
            text: "این عملیات غیر قابل بازگشت خواهد بود",
            icon: "warning",
            showCancelButton: true,
            showConfirmButton: true,
            dangerMode: true,
            buttons: ["خیر", "بله لغو کن"],
          })
          .then(async (willDelete) => {
            if (willDelete) {
              this.canceleOrderRequest(id);
            }
          });
      } else {
        this.canceleOrderRequest(id);
      }
    },
    closeModal(e){
      if(e.target&&e.target.id=='editAddressModal'){
        this.addressModal=false;
      }
    },
    chcekLastCanceleItem() {
      let res = 0;

      this.order.order.items.forEach((item) => {
        if (item.canceled == 0) {
          res++;
        }
      });
      return res === 1;
    },
    getOrder() {
      this.cancelOrderStatus = false;

      this.$store.dispatch(
        "front/getSingleOrderFromServer",
        this.$route.params.id
      );
    },
    canceleOrderItem(id) {
      window
        .swal({
          title: "آیا میخواهید محصول از سفارش شما حذف گردد؟",
          text: "این عملیات غیر قابل بازگشت خواهد بود",
          icon: "warning",
          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله حذف کن"],
        })
        .then(async (willDelete) => {
          if (willDelete) {
            this.canceleButtonStatus = false;
            this.$axios
              .delete(`customer/orders/${id}/items`)
              .then(() => {
                this.canceleButtonStatus = true;

                window.swal({
                  title: "محصول از سفارش شما حذف گردید",
                  icon: "success",
                  timer: 1500,
                });
                this.getOrder();
              })
              .catch((err) => {
                this.canceleButtonStatus = true;
                var massage0 = "مشکلی پیش آمده.";
                if (
                  err &&
                  err.response &&
                  err.response.data &&
                  err.response.data.message
                ) {
                  massage0 = err.response.data.message;
                }
                this.$notify({
                  group: "error",
                  title: "پیام سیستم",
                  text: massage0,
                  type: "error",
                  duration: "5000",
                });
                console.log(err);
              });
          }
        });
    },
    getCustomerAdderss() {
      return JSON.parse(this.order.order.address);
    },
    findPayments,
    findPriceOrder,
    getOrderStatus,
    getInvoicesStatus,
    getSumPrice,
    // دریافت عنوان
    getVarietyTitle(val) {
      let variety = JSON.parse(val);
      return product.getVarietyTitle(variety);
    },
    printInvoices() {
      // با کلیک بروی دکمه چاپ خروجی چاپگر نمایش داده شود
      const cssText = `

body {-webkit-print-color-adjust: exact;}
.showInPrint{
  display: block;
}
td {border-radius:23px!important;}
      #sumInvoSec{
        display:flex;
        justify-content:center !important;
      }
#headerInPrint {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
#headerInPrint img{
  width:200px;
}
#separator{
  display: none;
}
.opacity05{
  display: none;
}
#customerTabel tbody th,#customerTabel tbody td {
  border: 1px solid black !important;
}
#atlasTabel tbody th,#atlasTabel tbody td {
  border: 1px solid black !important;
}
#customerTabel tbody tr,
#atlasTabel tbody tr {
  border-top: 1px solid black !important;
}
#customerTabel,
#atlasTabel {
    direction: rtl;

  width: 100% !important;
  text-align: center;
}
tbody tr .markCell {
  background-color: #b3b3b3;
  width: 20% !important;
}
tbody tr .markCellHeader {
  background-color: #a3a3a3;
}
tbody tr .last_col {
  width: 20% !important;
}
tbody tr .col_2 {
  width: 40% !important;
}
#sumInvoices {
  width: 100%;
    direction: rtl;

  text-align: center;
}
#sumInvoices tbody th,#sumInvoices tbody td {
  border: 1px solid black !important;
}
#sumInvoices tbody tr {
  border-top: 1px solid black !important;
}
#sumInvoices .titleCell {
  width: 250px !important;
  background-color: #b3b3b3;
}
#sumInvoices .resultCell {
  width: 250px !important;
}
ul li {
  padding: 5px;
  padding-right: 20px;
}
ul {
    direction: rtl;

  margin-top: 10px;

}
  h1 {
    color: black;
    font-family: sans-serif;
  }
  .table {
    direction:rtl;
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
  }

  #invoices.table th,
    #invoices.table td ,
    #itemTable .table th,
    #itemTable .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }

    #invoices.table thead th ,#itemTable .table thead th{
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
`;
      const d = new Printd();
      d.print(document.getElementById("orderSection"), [cssText]);
    },
  },
  beforeDestroy() {
    this.$store.commit("front/setUserAddresses", null);
  },
  components: {
    PagesPath,
    BSkeleton,
  },
  beforeCreate() {
    // قبل از ساخت صفحه درخواست برای دریافت سفارش به سرور زده شود
    this.$store.dispatch(
      "front/getSingleOrderFromServer",
      this.$route.params.id
    );
  },
  computed: {
    userAddresses() {
      return this.$store.getters["front/getUserAddress"];
    },
    // گرفتن وضعیت دریافت سفارش از سرور
    status() {
      return this.$store.getters["front/getOrderStatus"];
    },
    // دریافت سفارش از ویو ایکس
    order() {
      return this.$store.getters["front/getOrder"];
    },

    user() {
      return this.$store.getters["front/getUserDetail"];
    },
    homeData() {
      return this.$store.getters["front/getHomeData"];
    },
  },
  watch: {
    order(newOrder) {
      if (newOrder.order.status == "new") {
        // this.$store.dispatch("front/getUserAddressesFromServer");
        this.selectedAddress=newOrder.order.address_id
      }
    },
  },
};
</script>
<style scoped>
.opacity05{
  opacity: 0.5;
}
/* افزودن کدهای سی اس به قالب های اضافه شده */
.disableButton {
  filter: grayscale(0.8);
  pointer-events: none;
}

.canceled {
  opacity: 0.8;
  background: #d7d7d7;
}
.table {
  width: 90%;
  margin: auto;
}
#separator {
  margin-right: 10%;
  margin-left: 10%;
}
#printBtn {
  background-color: #121314 !important;
  color: white !important;
  border: unset !important;
}
#trInvoicesHead,
#trItemTable {
  border-top: solid transparent !important;
}

#orderBox {
  width: 100%;
}
#itemTable {
  width: 90%;
  overflow: auto;
  margin: auto;
  margin-top: 15px;
}
#invoicesTable {
  width: 90%;
  overflow: auto;
  margin: auto;
}
#showInPrint {
  display: block;
}

#atlasLogoInPrint {
  position: fixed;
  top: 0;
  right: 0;
}
/*  */

.showInPrint {
  display: none;
}
#sumInvoSec {
  width: 100%;
}
.canceleOrder {
  padding: 5px 10px;
  background-color: rgb(182, 2, 2);
  color: white;
  box-shadow: 0 0 10px;
  border-radius: 5px;
}
.darkBack {
  background-color: #1b111117;
}

.canceleOrderHolder {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 30px auto;
  width: 81%;
}

.mainCanceleOrder {
  padding: 5px 10px;
  background-color: #c26431;
  color: white;
  box-shadow: 0 0 10px;
  border-radius: 5px;
}
a#gotoOrginalOrder {
  background: var(--color-theme);
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
}
#editAddress {
  background: #c26431;
  color: white;
  padding: 8px 16px;
  border-radius: 8px;
  margin-right: 8px;
}
.notAllow {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
#editAddressModal {
  position: fixed;
  inset: 0;
  background: #0000009c;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
#editAddressModal #content {
  background: white;
    padding: 15px;
    border-radius: 15px;
}
span.selectBox {
    width: 24px;
    height: 24px;
    background: #80808096;
    display: block;
    border-radius: 15px;
}
p.addresses {
    display: flex;
    align-items: center;
    gap: 8px;
}
span.selectBox.selectedAddress {
    background: green;
    box-shadow: 0 0 5px;
}
section#editAddressModal ul li button {
    padding: 4px 12px;
    border-radius: 4px;
    background: blue;
    color: white;
}
#editAddressModal ul{
  list-style: none;
}
</style>
