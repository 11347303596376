<template>
  <section class=" weblog-comments-section border-r-20 p-4 mt-4" style="background-color:#f9f9f9;">
    <div class="w-100">
      <div class="d-flex justify-content-start w-100" style="gap: 11px" v-if="blogStatus">
        <b-skeleton type="button" />
        <b-skeleton type="button" />
      </div>
      <div class="d-flex align-items-baseline gap-8" v-else>
        <span><svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 16.74C5.79 16.74 5.57249 16.6876 5.37749 16.5826C4.94999 16.3576 4.6875 15.9075 4.6875 15.4275V14.3626C2.4225 14.1301 0.9375 12.465 0.9375 10.08V5.58008C0.9375 3.00008 2.67 1.26758 5.25 1.26758H12.75C15.33 1.26758 17.0625 3.00008 17.0625 5.58008V10.08C17.0625 12.66 15.33 14.3925 12.75 14.3925H9.92249L6.72748 16.5226C6.50998 16.6651 6.255 16.74 6 16.74ZM5.25 2.38507C3.315 2.38507 2.0625 3.63757 2.0625 5.57257V10.0726C2.0625 12.0076 3.315 13.2601 5.25 13.2601C5.5575 13.2601 5.8125 13.5151 5.8125 13.8226V15.4201C5.8125 15.5176 5.8725 15.5626 5.91 15.5851C5.9475 15.6076 6.02251 15.6301 6.10501 15.5776L9.44252 13.3576C9.53252 13.2976 9.64501 13.2601 9.75751 13.2601H12.7575C14.6925 13.2601 15.945 12.0076 15.945 10.0726V5.57257C15.945 3.63757 14.6925 2.38507 12.7575 2.38507H5.25Z"
                    fill="#474747"
                  />
                  <path
                    d="M8.99986 9.08252C8.69236 9.08252 8.43736 8.82752 8.43736 8.52002V8.36255C8.43736 7.49255 9.07485 7.06504 9.31485 6.90004C9.59235 6.71254 9.68234 6.58505 9.68234 6.39005C9.68234 6.01505 9.37486 5.70752 8.99986 5.70752C8.62486 5.70752 8.31738 6.01505 8.31738 6.39005C8.31738 6.69755 8.06238 6.95255 7.75488 6.95255C7.44738 6.95255 7.19238 6.69755 7.19238 6.39005C7.19238 5.39255 8.00236 4.58252 8.99986 4.58252C9.99736 4.58252 10.8073 5.39255 10.8073 6.39005C10.8073 7.24505 10.1774 7.67253 9.94487 7.83003C9.65237 8.02503 9.56236 8.15255 9.56236 8.36255V8.52002C9.56236 8.83502 9.30736 9.08252 8.99986 9.08252Z"
                    fill="#474747"
                  />
                  <path
                    d="M9 10.95C8.685 10.95 8.4375 10.695 8.4375 10.3875C8.4375 10.08 8.6925 9.82495 9 9.82495C9.3075 9.82495 9.5625 10.08 9.5625 10.3875C9.5625 10.695 9.315 10.95 9 10.95Z"
                    fill="#474747"
                  />
                </svg></span>
        <h6 class="text-color-grey font-w-900 fontsize-medium m-0">ارسال نظرات</h6>
      </div>
      <br v-if="blogStatus"/>
      <p v-else class="d-inline-block text-color-444 fontsize-mini">
        نشانی ایمیل شما منتشر نخواهد شد
      </p>
      <CommentForm @commentAdded="onCommentAdded" v-if="blog" :blog-id="blog.post.id" />
    </div>
    <div class="white-bg mt-4 weblog-comments" id="legal-questions">
      <template v-if="blogStatus"><b-skeleton v-for="i in 4" :key="i"></b-skeleton></template>
      <!-- کامنت  -->
      <template v-if="blog && !blogStatus">
        <div v-for="(comment, index) in blog.post.comments" :key="'comment' + index" class="pb-md-0">
          <div class="user-question">
            
            <Comment @commentAdded="onCommentAdded" @answer="answer" :blog-id="blog.post.id" :answering-comment="answeringComment" :answerable="true" :comment="comment" />
          </div>
          <template v-if="comment.children.length != 0">
            <!-- پاسخ کامنت -->
            <div v-for="(childComment, index) in comment.children"
                 :key="'answer' + index" class="d-flex py-3 px-sm-5 pl-4 pr-1 admin-answer">
              <div class="answer-tab-content-img ml-3">
                <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.294 7.29105C17.294 10.2281 14.9391 12.5831 12 12.5831C9.0619 12.5831 6.70601 10.2281 6.70601 7.29105C6.70601 4.35402 9.0619 2 12 2C14.9391 2 17.294 4.35402 17.294 7.29105ZM12 22C7.66237 22 4 21.295 4 18.575C4 15.8539 7.68538 15.1739 12 15.1739C16.3386 15.1739 20 15.8789 20 18.599C20 21.32 16.3146 22 12 22Z" fill="#fff"/>
                </svg>
              </div>
              <Comment :answerable="false" :comment="childComment" />
            </div>
          </template>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import CommentForm from "@/parts/Front/components/weblog/CommentForm";
import Comment from "@/parts/Front/components/weblog/Comment";
import {BSkeleton} from 'bootstrap-vue'

export default {
  name: "CommentSection",
  components: {Comment, CommentForm, BSkeleton},
  data() {
    return {
      answeringComment: null
    }
  },
  methods: {
    answer(commentId) {
      if (this.answeringComment === commentId) {
        this.answeringComment = null
      } else {
        this.answeringComment = commentId
        this.$nextTick(() => {
          document.querySelector('#form-' + commentId + ' input').focus();
        })
      }
    },
    onCommentAdded(comment) {
      if (comment) {
        this.blog.post.comments.unshift(comment)
      }
      this.answeringComment = null;
    }
  },
  computed: {
    blogStatus() {
      return this.$store.getters["front/getBlogDetailStatus"];
    },
    blog() {
      return this.$store.getters["front/getBlogDetail"];
    },
  },
}
</script>
