<template>
  <div>
    <PagesPath :pathTitle1="'درباره ما'" :pathTo1="'about-us'"></PagesPath>
    <div class=" content-about box-shaddow20">

    <div class="image-about p-0 border-r-20 container mt-4 mb-3">
      <figure class="border-r-20">
        <img class="w-100 border-r-20" src="@/assets/images/image-about.png" alt="image-about">
      </figure>
    </div>
    <section class="p-3 border-r-20  container mt-4 mb-5">
      <div class="d-flex justify-content-between align-items-baseline gap-40">
        <h5 class="text-color-444 my-4 font-weight-bold">
          <span class="font-weight-bold">
            فروشگاه
          </span>
          <span class="text-color-theme font-weight-bold">
            بندیتو
          </span>
           
         </h5>
         <span class="line-about"></span>
         <p class="text-color-999">
          فروش بروزترین روغن ها
         </p>
      </div>
      <p class="line-height2 text-color-666 fontsize14 text-justify">
        این کسب و کار از اونجایی شروع شد که در منطقه ی ترکمن نشین های استان گلستان حدود ۵۰ – ۶۰ سال پیش از دانه ی کنجد به روش سنتی روغن گیری می شد و از اون جایی که خواص و کیفیت این روغن بسیار بالا بود ، روغن کنجد به نام ملکه ی روغن ها نامیده شد .

        فروشگاه تولیدی روغن های  زیبایی ، درمانی و خوراکی سلامتی کار خود را از شهریور ۹۴ در استان گلستان که یکی از مشهور ترین و پیشرو ترین استان ها در زمینه ی تولید و کشت دانه های روغنی است آغاز کرد .

        امروزه متخصصانی که در زمینه ی روغن گیری (روغنکشی) مشغول به کار هستند از روش های مختلفی برای استحصال روغن استفاده می کنند .یکی از این روش ها روغنگیری به روش پرس سرد ( کلد پرس ) میباشد.

        روغن بدست آمده به روش پرس سرد با تاییده استاندارهای جهانی بهترین روغن از نظر ارزش تغذیه ای شناخته شده است . در این روش روغن با فشار مکانیکی روی دانه های روغنی زیر دمای ۴۵ درجه سانتی گراد استخراج می شود.

        آنتی اکسیدان ، املاح و ویتامینهای طبیعی موجود در روغن تولید شده به روش پرس سرد کاملا در روغن استحصال شده حفظ می شود در صورتی که در روش های دیگر از بین رفته و تصفیه می شود.

        در این روش از هیچ­گونه ترکیب شیمیایی استفاده نمی‌شود . روغن با استفاده از اعمال فشار به دانه ها در دمای پایین و بدون وارد کردن هرگونه آسیب به ساختار دانه‌ تولید می‌شود که در مرحله‌ی بعد، لِرد موجود در روغن تصفیه نمی شود بلکه بوسیله‌ی فیلتر جداسازی شده (فیلتر پرس و فیلتر میکرون ) و در آخر روغن با بالاترین سطح کیفیت آماده‌ی استفاده می‌شود.

        در فروشگاه تولیدی روغن های خوراکی ، زیبایی و درمانی سلامتی  تمام دانه های روغنی به روش پرس سرد زیر نظر کارشناس مواد غذایی و اداره بهداشت  روغن گیری می شوند، تمام محصولات از مرغوب ترین و بهترین دانه های موجود در بازار استفاده شده تا با بهترین کیفیت و ضمانت خلوص به سراسر دنیا ارائه دهیم.      </p>
    </section>
    <section class="Trusted-customers">
      <TrustedCustomers/>
    </section>
    <section>
      <CollaborateWith/>
    </section>
    <section>
      <infoDownAbout></infoDownAbout>
    </section>
  </div>
    <Footer></Footer>
  </div>
</template>

<script>
import PagesPath from "@/parts/Front/components/PagesPath";
import TrustedCustomers from "@/parts/Front/components/about-us/Trusted-customers";
import CollaborateWith from "@/parts/Front/components/about-us/Collaborate-with";
import infoDownAbout from "@/parts/Front/components/about-us/info-down-about";
export default {
  name: "AboutUs",
  components:{
    PagesPath,
    TrustedCustomers,
    CollaborateWith ,
    infoDownAbout
  },
  data() {
    return {
      options: [
        {
          'title': 'تضمین اصالت'
        },
        {
          'title': 'قیمت مناسب'
        },
        {
          'title': 'کیفیت خوب'
        }
      ]
    }
  },
  mounted() {
    // let aboutUsImg=document.querySelectorAll('.about-us-why-img')[0];
    // aboutUsImg.style.height = (aboutUsImg.offsetWidth * 1.2) + "px";
    // window.addEventListener('resize' , function() {
    //   let aboutUsImg=document.querySelectorAll('.about-us-why-img')[0];
    //   aboutUsImg.style.height = (aboutUsImg.offsetWidth * 1.2) + "px";
    // });
  }
}
</script>

<style scoped>
.line-about{
  height: 1px;
  background-color: #E7E7E7;
  flex-grow: 1;
}
.image-about{
  position: relative;
  top: -200px;
  margin: 0 auto !important;
  margin-bottom: -170px !important;
}
.content-about{
  margin-top: 230px;
}
.image-about img{
  object-fit: cover;
}
@media screen and (max-width:998px) {
  .image-about{
    position: relative;
    top: -95px;
    margin-bottom: -80px !important;
  }
  .content-about{
    margin-top: 125px;
    
  }
  
}
@media screen and (max-width:768px) {
  .image-about{
    
    padding: 0 30px !important;
    height: 210px;
    
  }
  .image-about img{
    object-fit: cover;
    height: 210px;
  }
 
  
}
</style>
