<template>
  <section id="AllCategoriesSection" v-if="categories && categories.length">
    <div class="container">
      <div
        class="pt-5 pb-5"
        :class="
          category.children && category.children.length
            ? 'w-100'
            : 'single-categories'
        "
        v-for="(category, index) in categories"
        :key="index"
      >
        <h2
          class="fontsize-regular font-w-800 text-color-grey text-center mb-5"
        >
          {{ category.title }}
        </h2>
        <div
          class="w-100 d-flex align-items-center border-r-45 categories-children-container"
          :class="mediaQueries.mobileSize ? 'swiper-container' : 'overflow-hidden'"
          v-if="category.children && category.children.length"
        >
          <div
            class="w-100 d-flex align-items-center  "
            :class="{ 'swiper-wrapper': mediaQueries.mobileSize }"
          >
            <router-link
              :to="{
                name: 'product.category',
                params: {
                  category: childCategory.id,
                  slug: childCategory.slug,
                },
                query: query,
              }"
              class="py-2 d-flex align-items-center flex-column categories-children swiper-slide"
              :class="
                category.children && category.children.length
                  ? 'width-25'
                  : '' + ' ' + mediaQueries.mobileSize
                  ? 'swiper-slide'
                  : ''
              "
              v-for="(childCategory, index) in category.children"
              :key="index"
            >
              <!-- <img v-if="childCategory.image" :src="''" :alt="childCategory.title"> -->
              <img
                class="w-100"
                src="https://api.benedito.ir/storage/f2c34743-b53c-4bbe-bce3-f643778357a7/product-119.jpg"
                alt=""
              />
              <h4 class="fontsize-small font-w-800 text-color-grey">
                {{ childCategory.title }}
              </h4>
            </router-link>
          </div>
        </div>
        <div class="border-r-45 overflow-hidden categories-children-container" v-else>
          <router-link
            :to="{
              name: 'product.category',
              params: { category: category.id, slug: category.slug },
              query: query,
            }"
            class="py-2 d-flex align-items-center flex-column categories-children"
            :class="
              category.children && category.children.length ? '' : 'w-100'
            "
          >
            <!-- <img v-if="childCategory.image" :src="''" :alt="childCategory.title"> -->
            <img
              class="w-100"
              src="https://api.benedito.ir/storage/f2c34743-b53c-4bbe-bce3-f643778357a7/product-119.jpg"
              alt=""
            />
            <h4 class="fontsize-small font-w-800 text-color-grey">
              {{ category.title }}
            </h4>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Swiper from "swiper/swiper-bundle";
export default {
  name: "AllCategoties",
  inject: ["mediaQueries"],
  props: {
    query: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.initAllCategories();
  },
  watch: {
    categories(newValue) {
      if (newValue) {
        this.initAllCategories(true);
      }
    },
  },
  computed: {
    categories() {
      if (this.$store.getters["front/getHomeData"] != null) {
        return this.$store.getters["front/getHomeData"].categories;
      } else {
        return null;
      }
    },
  },
  methods: {
    initAllCategories(kill = false) {
      this.initSwiper("allCategoriesSwiper", kill, () => {
        new Swiper("#AllCategoriesSection .swiper-container", {
          slidesPerView: 1,
          spaceBetween: 10,
          loop: false,
          centeredSlides: false,
          centeredSlidesBounds: false,
          speed: 1000,
          autoplay: false,
        });
      });
    },
  },
};
</script>
<style scoped>
#AllCategoriesSection .categories-children-container {
  background-color: #ffffff;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.2);
  /* overflow: hidden; */
}
#AllCategoriesSection .categories-children:not(:first-child) {
  border-right: 1px solid #e5e5e5;
}
#AllCategoriesSection .categories-children {
  position: relative;
  width: 25%;
  overflow: hidden;
}
@media screen and (min-width: 992px) {
  #AllCategoriesSection .categories-children:hover::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(235, 111, 167, 0.5009978991596639) 85%
    );
  }
}
@media screen and (min-width: 769px) {
  #AllCategoriesSection .single-categories {
    display: inline-block;
    margin-left: 1rem;
    width: 23%;
  }
  #AllCategoriesSection .width-25 {
    width: 25%;
  }
}
@media screen and (max-width: 768px) {
  #AllCategoriesSection .categories-children {
    position: relative;
    width: 50%;
    /* border-bottom: 1px solid #e5e5e5; */
  }
  #AllCategoriesSection .single-categories {
    display: inline-block;
    margin-left: 1rem;
    width: 45%;
  }
}
@media screen and (max-width: 540px) {
  #AllCategoriesSection .categories-children {
    position: relative;
    width: 100%;
  }
  #AllCategoriesSection .single-categories {
    display: inline-block;
    width: 100%;
  }
}
</style>
